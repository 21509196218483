<template>
  <div v-if="selectedChild">

    <md-tabs id="childTabsId" md-dynamic-height md-elevation="1" @md-changed="onTabsChanged">
      <template slot="md-tab" slot-scope="{ tab }">
        <md-icon>{{tab.icon}}</md-icon>
        <span class="md-tab-label" style="margin-top: 10px;">{{ tab.label }} <i class="badge" v-if="tab.data.badge">1</i></span>
        <md-tooltip>
          <div v-if="tab.data.badge">Einwilligung zur Datenverarbeitung für <b>{{tab.data.fullName}}</b> fehlt!</div>
          <div v-if="tab.data.tip">{{tab.data.tip}}</div>
        </md-tooltip>
      </template>

      <md-tab id="CHILD" md-label="Kind" md-icon="face"
              :md-template-data="{ badge: !selectedChild.masterdata.dsgvoConfirmationObtained, fullName: selectedChild.masterdata.fullName }">

        <md-card-content v-if="selectedChild" :style="mdCardContentStyle">
          <div v-if="editActive">
            <h5>Kind</h5>
            <PersonForm ref="childPersonForm" :person="selectedChild.masterdata" sexEnabled="true"
                        @updateBirthDate="selectedChild.masterdata.birthdate = arguments[0]"
                        salutationDisabled="true" titleDisabled="true" :sending="sending"></PersonForm>
            <br>
            <h5>Adresse</h5>
            <AddressForm ref="childAddressForm" :address="selectedChild.masterdata.address"
                         :sending="sending"></AddressForm>
            <br>
            <h5>Notfall</h5>
            <div class="grid-x grid-padding-x">
              <div class="cell">
                <md-field>
                  <label>Notfall-Ansprechpartner</label>
                  <md-select v-model="selectedChild.emergencyPersonIds" autocomplete="off"
                             @md-selected="onDetermineEmergencyPhoneNumber" :disabled="sending || selectedChild.relatedPersons.length === 0" multiple>
                    <md-option v-for="relatedSrcPerson of selectedChild.relatedPersons" :value="relatedSrcPerson.id" :key="relatedSrcPerson.id">
                      {{relatedSrcPerson.fullName}} ({{labels.childRelation[relatedSrcPerson.childRelation]}})
                    </md-option>
                  </md-select>
                  <md-tooltip v-if="selectedChild.relatedPersons.length === 0">
                    Noch keine Bezugsperson zur Auswahl verfügbar.<br>
                    Bitte fügen Sie erst eine Bezugsperson hinzu.
                  </md-tooltip>
                </md-field>
              </div>
              <div class="cell">
                <ChildView :child="selectedChild" :emergency="true" :emergency-light="true"/>
              </div>
              <div class="cell">
                <md-field :class="getValidationClass('emergencyPhonenumber')">
                  <label for="emergencyPhonenumber">Alternative Notfall-Rufnummer</label>
                  <md-input type="text" name="emergencyPhonenumber" id="emergencyPhonenumber"
                            autocomplete="off"
                            v-model="selectedChild.emergencyPhonenumber" :disabled="sending"/>
                  <span class="md-error" v-if="!$v.selectedChild.emergencyPhonenumber.required">Notfall-Rufnummer ist notwendig</span>
                  <span class="md-error" v-else-if="!$v.selectedChild.emergencyPhonenumber.minLength">Notfall-Rufnummer muss mindestens 4 Ziffern lang sein</span>
                </md-field>
              </div>
            </div>
            <br>

            <md-card class="md-card-flat">
              <md-card-expand>
                <md-card-actions md-alignment="left" style="padding: 0;">
                  <md-card-expand-trigger>
                    <md-button class="md-icon-button">
                      <md-icon>keyboard_arrow_down</md-icon>
                      <md-tooltip>Bearbeite Kinderarzt-Daten</md-tooltip>
                    </md-button>
                  </md-card-expand-trigger>
                  <h5 style="padding-left: 10px;padding-top: 6px;">Kinderarzt</h5>
                </md-card-actions>
                <md-card-expand-content>
                  <md-card-content style="padding-bottom: 8px; padding-left: 0; padding-right: 0;">
                        <PersonForm ref="pediatricianPersonForm" :person="selectedChild.healthInformation.pediatrician"
                                    :birthDateDisabled="true" :gdprDisabled="true" :sending="sending"></PersonForm>
                        <AddressForm ref="pediatricianAddressForm" :address="selectedChild.healthInformation.pediatrician.address"
                                     :sending="sending"></AddressForm>
                        <ContactForm ref="pediatricianContactForm" :contact="selectedChild.healthInformation.pediatrician.contact"
                                     :sending="sending"></ContactForm>
                  </md-card-content>
                </md-card-expand-content>
              </md-card-expand>
            </md-card>
            <br>

            <md-card class="md-card-flat">
              <md-card-expand>
                <md-card-actions md-alignment="left" style="padding: 0;">
                  <md-card-expand-trigger>
                    <md-button class="md-icon-button">
                      <md-icon>keyboard_arrow_down</md-icon>
                      <md-tooltip>Bearbeite Versicherungs-Daten</md-tooltip>
                    </md-button>
                  </md-card-expand-trigger>
                  <h5 style="padding-left: 10px;padding-top: 6px;">Krankenversicherung</h5>
                </md-card-actions>
                <md-card-expand-content>
                  <md-card-content style="padding-bottom: 8px; padding-left: 0; padding-right: 0;">
                    <ChildForm ref="childFormInsurance" :child="selectedChild" :insurance="true" :sending="sending"></ChildForm>
                  </md-card-content>
                </md-card-expand-content>
              </md-card-expand>
            </md-card>

            <br>
            <h5>Merkmale</h5>
              <ChildForm ref="childForm" :child="selectedChild" :sending="sending"
                         @saveAndExitTo="(route) => $emit('saveAndExitTo', route)"></ChildForm>
            <div v-if="mode === 'update'">
              <br>
              <h5>KiBiG-Web</h5>
              <ChildForm ref="childFormKibig" :child="selectedChild" :kibig="true" :sending="sending"></ChildForm>
              <md-card class="md-card-flat">
                <md-card-expand>
                  <md-card-actions md-alignment="left" style="padding: 0;">
                    <md-card-expand-trigger>
                      <md-button class="md-icon-button">
                        <md-icon>keyboard_arrow_down</md-icon>
                        <md-tooltip>Bearbeite Beitragsersatz-Daten</md-tooltip>
                      </md-button>
                    </md-card-expand-trigger>
                    <h5 style="padding-left: 10px;padding-top: 6px;">Beitragsersatz</h5>
                  </md-card-actions>
                  <md-card-expand-content>
                    <md-card-content style="padding-bottom: 8px; padding-left: 0; padding-right: 0;">
                      <ChildForm ref="childFormCompensation" :child="selectedChild" :compensation="true" :sending="sending"></ChildForm>
                    </md-card-content>
                  </md-card-expand-content>
                </md-card-expand>
              </md-card>
            </div>
          </div>
          <div v-else>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>Kind</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <PersonView :person="selectedChild.masterdata" :sexEnabled="true"
                            :salutationDisabled="true" :titleDisabled="true" :noAddress="true"/>
              </div>
            </div>
            <hr>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>Adresse</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <AddressView :address="selectedChild.masterdata.address"/>
              </div>
            </div>
            <hr>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>Notfall</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <ChildView :child="selectedChild" :emergency="true"/>
              </div>
            </div>
            <hr>
            <md-card class="md-card-flat">
              <md-card-expand>
                <md-card-actions md-alignment="left" style="padding: 0;">
                  <md-card-expand-trigger>
                    <md-button class="md-icon-button">
                      <md-icon>keyboard_arrow_down</md-icon>
                      <md-tooltip>Zeige Kinderarzt-Details</md-tooltip>
                    </md-button>
                  </md-card-expand-trigger>
                  <h5 style="padding-left: 10px;padding-top: 6px;">Kinderarzt</h5>
                </md-card-actions>
                <md-card-expand-content>
                  <md-card-content style="padding: 0 0 8px 0;">
                    <div class="grid-x">
                      <div class="cell large-3 medium-5 small-4">
                      </div>
                      <div class="cell large-9 medium-7 small-8">
                        <PersonView :person="selectedChild.healthInformation.pediatrician" :birthDateDisabled="true"
                                    :salutationDisabled="true" :gdprDisabled="true"/>
                        <ContactView :contact="selectedChild.healthInformation.pediatrician.contact"/>
                      </div>
                    </div>
                  </md-card-content>
                </md-card-expand-content>
              </md-card-expand>
            </md-card>
            <hr>
            <md-card class="md-card-flat">
              <md-card-expand>
                <md-card-actions md-alignment="left" style="padding: 0;">
                  <md-card-expand-trigger>
                    <md-button class="md-icon-button">
                      <md-icon>keyboard_arrow_down</md-icon>
                      <md-tooltip>Zeige Versicherungs-Details</md-tooltip>
                    </md-button>
                  </md-card-expand-trigger>
                  <h5 style="padding-left: 10px;padding-top: 6px;">Krankenversicherung</h5>
                </md-card-actions>
                <md-card-expand-content>
                  <md-card-content style="padding: 0 0 8px 0;">
                    <div class="grid-x">
                      <div class="cell large-3 medium-5 small-4">
                      </div>
                      <div class="cell large-9 medium-7 small-8">
                        <ChildView :child="selectedChild" :insurance="true"/>
                      </div>
                    </div>
                  </md-card-content>
                </md-card-expand-content>
              </md-card-expand>
            </md-card>
            <hr>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>Merkmale</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <ChildView :child="selectedChild"/>
              </div>
              <div class="cell">
                <br>
                <ChildView :child="selectedChild" :characteristics="true"/>
              </div>
            </div>
            <hr>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>KiBiG-Web</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <ChildView :child="selectedChild" :kibig="true"/>
              </div>
            </div>
            <hr>
            <md-card class="md-card-flat">
              <md-card-expand>
                <md-card-actions md-alignment="left" style="padding: 0;">
                  <md-card-expand-trigger>
                    <md-button class="md-icon-button">
                      <md-icon>keyboard_arrow_down</md-icon>
                      <md-tooltip>Zeige Beitragsersatz-Details</md-tooltip>
                    </md-button>
                  </md-card-expand-trigger>
                  <h5 style="padding-left: 10px;padding-top: 6px;">Beitragsersatz</h5>
                </md-card-actions>
                <md-card-expand-content>
                  <md-card-content style="padding: 0 0 8px 0;">
                    <div class="grid-x">
                      <div class="cell large-3 medium-5 small-4">
                      </div>
                      <div class="cell large-9 medium-7 small-8">
                        <ChildView :child="selectedChild" :compensation="true"/>
                      </div>
                    </div>
                  </md-card-content>
                </md-card-expand-content>
              </md-card-expand>
            </md-card>
          </div>
        </md-card-content>
      </md-tab>

      <md-tab v-for="relatedPerson in selectedChild.relatedPersons"
              :md-label="labels.childRelation[relatedPerson.childRelation]"
              :md-icon="getPickUpEntitledIcon(relatedPerson)"
              :id="relatedPerson.childRelation" :key="relatedPerson.childRelation"
              :md-template-data="{ badge: !relatedPerson.dsgvoConfirmationObtained, fullName: relatedPerson.fullName }">
        <md-card-content v-if="selectedChild" :style="mdCardContentStyle">
          <div v-if="editActive">
            <div class="grid-x">
              <div class="cell small-10">
                <h5>{{labels.childRelation[relatedPerson.childRelation]}}</h5>
              </div>
              <div class="cell small-2 text-right">
                <md-button class="md-icon-button md-raised md-primary" @click="onDeleteRelatedPerson(relatedPerson)">
                  <md-icon>delete</md-icon>
                  <md-tooltip md-direction="left">Datensatz für
                    <b>{{labels.childRelation[relatedPerson.childRelation]}}</b> löschen
                  </md-tooltip>
                </md-button>
              </div>
            </div>
            <PersonForm ref="relatedPersonForm" :person="relatedPerson" :sending="sending" editMore="true"></PersonForm>

            <div class="grid-x grid-padding-x">
              <div class="cell large-3 medium-4 small-4">
                <br>
                <h5>Adresse</h5>
              </div>
              <div class="cell large-1 medium-1 small-1">
                <br>
                <div v-if="copying">
                  <vue-simple-spinner></vue-simple-spinner>
                </div>
              </div>
              <div class="cell large-2 medium-1 small-1">
                <br>
              </div>
              <div class="cell large-6 medium-6 small-6">
                <md-field>
                  <label>Übernehmen von</label>
                  <md-select v-model="newRelatedPersonChildRelationSrc" autocomplete="off"
                             @md-selected="onCopyRelatedPersonAddress(relatedPerson)" :disabled="sending">
                    <md-option value="CHILD">
                      Kind
                    </md-option>
                    <md-option v-for="relatedSrcPerson of selectedChild.relatedPersons"
                               v-if="relatedSrcPerson.childRelation !== relatedPerson.childRelation"
                               :value="relatedSrcPerson.childRelation" :key="relatedSrcPerson.childRelation">
                      {{labels.childRelation[relatedSrcPerson.childRelation]}}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>

            <AddressForm ref="relatedPersonAddressForm" :address="relatedPerson.address"
                         :sending="sending"></AddressForm>
            <h5>Kontakt</h5>
            <ContactForm ref="relatedPersonContactForm" :contact="relatedPerson.contact"
                         :sending="sending"></ContactForm>
          </div>
          <div v-else>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>{{labels.childRelation[relatedPerson.childRelation]}}</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <PersonView :facilityRef="selectedChild.facilityReference" :person="relatedPerson" :showPortalLogin="true" :noAddress="true" :showMore="true"/>
              </div>
            </div>
            <hr>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>Adresse</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <AddressView :address="relatedPerson.address"/>
              </div>
            </div>
            <hr>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>Kontakt</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <ContactView :contact="relatedPerson.contact"/>
              </div>
            </div>
          </div>
        </md-card-content>
      </md-tab>

      <md-tab v-if="editActive && addPlusTab" md-label="Neu" id="NEW" md-icon="person_add" :md-template-data="{ tip: 'Neue Bezugsperson anlegen'}">
        <md-card-content v-if="selectedChild" style="height: 615px; overflow-x: auto; padding: 0; margin-top: 0;">
          <div class="grid-x grid-padding-x">
          <div class="cell large-6 medium-6 small-6">
            <md-field>
              <label>Verwandtschaftsbeziehung</label>
              <md-select v-model="newRelatedPersonChildRelation" autocomplete="off"
                         :disabled="sending">
                <md-option v-for="childRelationType of availableChildRelationTypes" :value="childRelationType" :key="childRelationType">
                  {{labels.childRelation[childRelationType]}}
                </md-option>
              </md-select>
            </md-field>
          </div>
          <div class="cell large-6 medium-6 small-6">
            <br>
            <div @click="onAddRelatedPerson" class="button expanded"
                 :disabled="newRelatedPersonChildRelation === 'NEW'">
              <span><img width="15px"
                         src="../../assets/icons/mother-sample.png"/>&nbsp;&nbsp;&nbsp;Jetzt hinzufügen</span>
            </div>
          </div>
        </div>
        </md-card-content>
      </md-tab>

    </md-tabs>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteRelatedPersonDialog">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><img width="25px" src="../../assets/icons/child-torso.png"/>&nbsp;&nbsp;&nbsp;Datensatz für
          {{personToBeDeletedLabel}}
          löschen
        </md-dialog-title>
        <div class="cell">
          <md-content>Soll der Datensatz für <b>{{personToBeDeletedLabel}}</b> wirklich gelöscht werden?</md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <div @click="deleteRelatedPerson" class="button alert"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
            Datensatz jetzt löschen
          </div>
          <button class="button success" style="margin-left: 1rem;"
                  @click="showDeleteRelatedPersonDialog = false"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showDeleteRelatedPersonDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

  </div>
</template>

<script>

  import jQuery from 'jquery';
  import {validationMixin} from 'vuelidate'
  import Person from "../../entities/Person"
  import Spinner from 'vue-simple-spinner'

  import {
    required,
    minLength,
  } from 'vuelidate/lib/validators'

  import AddressView from "../../components/views/AddressView";
  import ContactView from "../../components/views/ContactView";
  import PersonView from "../../components/views/PersonView";
  import ChildView from "../../components/views/ChildView";

  import PersonForm from "../forms/PersonForm";
  import ChildForm from "../forms/ChildForm";
  import AddressForm from "../../components/forms/AddressForm";
  import ContactForm from "../../components/forms/ContactForm";

  export default {
    name: 'ChildTabs',
    mixins: [validationMixin],

    components: {
      AddressView,
      ContactView,
      PersonView,
      ChildView,

      ChildForm,
      PersonForm,
      AddressForm,
      ContactForm,

      Person,
      'vue-simple-spinner': Spinner,
    },


    mounted() {
      this.colorIcons();
      this.updateAvailableChildRelationTypes();
    },

    props: {
      selectedChild: {
        required: true
      },
      editActive: {
        required: false
      },
      mode: {
        required: false
      },
      sending: {
        required: false
      },
      mdCardContentStyle: {
        required: false
      },
    },

    watch: {
      editActive() {
        setTimeout(() => jQuery('#childTabsId').children('.md-content.md-tabs-content').attr('style', 'height: 100%'), 50);
      },

      selectedChild() {
        this.colorIcons();
        this.updateAvailableChildRelationTypes();
      },
    },

    data: () => ({
      newRelatedPersonChildRelation: 'NEW',
      newRelatedPersonChildRelationSrc: '',
      addPlusTab: false,
      availableChildRelationTypes: [],
      showDeleteRelatedPersonDialog: false,
      personToBeDeleted: null,
      personToBeDeletedLabel: '',
      copying: false,
      activeTabId: '',
    }),

    validations: {
      selectedChild: {
        emergencyPhonenumber: {
          required,
          minLength: minLength(4),
        },
      },
    },

    methods: {

      onDetermineEmergencyPhoneNumber() {
        for (let srcRelatedPerson of this.selectedChild.relatedPersons) {
          if (srcRelatedPerson.childRelation === this.selectedChild.emergencyName) {
            if (srcRelatedPerson.contact && srcRelatedPerson.contact.mobilePhone) {
              this.selectedChild.emergencyPhonenumber = srcRelatedPerson.contact.mobilePhone;
            }
            else if (srcRelatedPerson.contact && srcRelatedPerson.contact.phone) {
              this.selectedChild.emergencyPhonenumber = srcRelatedPerson.contact.phone;
            }
          }
        }
      },

      onCopyRelatedPersonAddress(dstRelatedPerson) {
        if (this.newRelatedPersonChildRelationSrc.length > 0 && dstRelatedPerson.childRelation === this.activeTabId) {
          for (let srcRelatedPerson of this.selectedChild.relatedPersons) {
            if (srcRelatedPerson.childRelation === this.newRelatedPersonChildRelationSrc) {
              this.copying = true;
              setTimeout(() => {
                  dstRelatedPerson.address = JSON.parse(JSON.stringify(srcRelatedPerson.address));
                  this.newRelatedPersonChildRelationSrc = '';
                  this.copying = false;
              }, 750);
              return;
            }
          }
          if (this.newRelatedPersonChildRelationSrc === 'CHILD') {
            this.copying = true;
            setTimeout(() => {
              dstRelatedPerson.address = JSON.parse(JSON.stringify(this.selectedChild.masterdata.address));
              this.newRelatedPersonChildRelationSrc = '';
              this.copying = false;
            }, 500);
          }
        }
      },

      onTabsChanged(tabId) {
        this.activeTabId = tabId;
        setTimeout(() => jQuery('#childTabsId').children('.md-content.md-tabs-content').attr('style', 'height: 100%'), 50);
      },

      updateAvailableChildRelationTypes() {
        this.availableChildRelationTypes = [];
        for (let childRelationType of this.labels.childRelationTypes) {
          let canAdd = true;
          for (let relatedPerson of this.selectedChild.relatedPersons) {
            if (relatedPerson.childRelation === childRelationType || childRelationType === 'SELF') {
              canAdd = false;
            }
          }
          if (canAdd) {
            this.availableChildRelationTypes.push(childRelationType);
          }
        }

        this.addPlusTab = false;
        if (this.availableChildRelationTypes.length > 0) {
          setTimeout(() => this.addPlusTab = true, 50);
        }
      },

      provideNewRelatedPerson() {
        let newRelatedPerson = JSON.parse(JSON.stringify(Person.person));
        newRelatedPerson.childRelation = this.newRelatedPersonChildRelation;
        this.selectedChild.relatedPersons.push(newRelatedPerson);
        this.newRelatedPersonChildRelation = 'NEW';
        this.updateAvailableChildRelationTypes();
        this.selectedLastTab();
      },

      selectedLastTab() {
        setTimeout(() => {
          jQuery('#childTabsId .md-tabs-navigation button:nth-child(' + (this.selectedChild.relatedPersons.length + 1) + ')').trigger('click');
        }, 50);
      },

      selectedFirstTab() {
        setTimeout(() => {
          jQuery('#childTabsId .md-tabs-navigation button:nth-child(' + 1 + ')').trigger('click');
        }, 50);
      },

      onAddRelatedPerson() {
        if (this.newRelatedPersonChildRelation !== 'NEW') {
          this.provideNewRelatedPerson();
        }
      },

      onDeleteRelatedPerson(relatedPerson) {
        this.showDeleteRelatedPersonDialog = true;
        this.personToBeDeleted = relatedPerson;
        this.personToBeDeletedLabel = this.labels.childRelation[relatedPerson.childRelation];
      },

      deleteRelatedPerson() {
        this.showDeleteRelatedPersonDialog = false;

        let i = 0;
        for (let relatedPerson of this.selectedChild.relatedPersons) {
          if (this.personToBeDeleted.childRelation === relatedPerson.childRelation) {
            this.selectedChild.relatedPersons.splice(i, 1);
            this.updateAvailableChildRelationTypes();
            setTimeout(() => {
              jQuery('#childTabsId .md-tabs-navigation button:nth-child(1)').trigger('click');
            }, 50);

            return;
          }
          i++;
        }
      },

      getPickUpEntitledIcon(person) {
        this.colorIcons();
        if (person.pickUpEntitled) {
          return 'check_circle_outline';
        } else {
          return 'error_outline';
        }
      },

      colorIcons() {
        setTimeout(() => {
          jQuery('.md-button-content').children("i.md-icon:contains('check_circle_outline')").each(function () {
            jQuery(this).attr("style", "color:green;");
          });
          jQuery('.md-button-content').children("i.md-icon:contains('error_outline')").each(function () {
            jQuery(this).attr("style", "color:red;");
          });
        }, 50);
      },

      getValidationClass(fieldName) {
        let field = this.$v.selectedChild[fieldName];
        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      reset() {
        this.$v.$reset();
        if (this.$refs.childPersonForm && this.$refs.childForm && this.$refs.childAddressForm &&
            this.$refs.pediatricianPersonForm && this.$refs.pediatricianAddressForm && this.$refs.pediatricianContactForm &&
            this.$refs.childFormInsurance && this.$refs.childFormKibig && this.$refs.childFormCompensation) {
          this.$refs.childPersonForm.$v.$reset();
          this.$refs.childForm.$v.$reset();
          this.$refs.childAddressForm.$v.$reset();
          this.$refs.pediatricianPersonForm.$v.$reset();
          this.$refs.pediatricianAddressForm.$v.$reset();
          this.$refs.pediatricianContactForm.$v.$reset();
          this.$refs.childFormInsurance.$v.$reset();
          this.$refs.childFormKibig.$v.$reset();
          this.$refs.childFormCompensation.$v.$reset();
        }

        if (this.$refs.relatedPersonForm && this.$refs.relatedPersonAddressForm && this.$refs.relatedPersonContactForm) {
          for (let index = 0; index < this.selectedChild.relatedPersons.length; index++) {
            if (this.$refs.relatedPersonForm[index] && this.$refs.relatedPersonAddressForm[index] && this.$refs.relatedPersonContactForm[index]) {
              this.$refs.relatedPersonForm[index].$v.$reset();
              this.$refs.relatedPersonAddressForm[index].$v.$reset();
              this.$refs.relatedPersonContactForm[index].$v.$reset();
            }
          }
        }
      },

      validateChild() {
        this.$v.$touch();
        this.invalid = this.$v.$invalid;

        if (this.$refs.childPersonForm && this.$refs.childForm && this.$refs.childAddressForm &&
            this.$refs.pediatricianPersonForm && this.$refs.pediatricianAddressForm &&
            this.$refs.childFormInsurance && this.$refs.childFormKibig && this.$refs.childFormCompensation) {
          this.$refs.childPersonForm.validatePerson();
          this.$refs.childForm.validateChild();
          this.$refs.childAddressForm.validateAddress();
          this.$refs.pediatricianPersonForm.validatePerson();
          this.$refs.pediatricianAddressForm.validateAddress();
          this.$refs.pediatricianContactForm.validateContact();
          this.$refs.childFormInsurance.validateChild();
          this.$refs.childFormKibig.validateChild();
          this.$refs.childFormCompensation.validateChild();

          if (this.$refs.childPersonForm.invalid || this.$refs.childForm.invalid || this.$refs.childAddressForm.invalid ||
              this.$refs.childFormInsurance.invalid || this.$refs.childFormKibig.invalid || this.$refs.childFormCompensation.invalid) {
            jQuery('#childTabsId .md-tabs-navigation').children(':button.md-button').first().trigger('click');
            this.invalid = true;
            return;
          }
        }

        if (this.$refs.relatedPersonForm && this.$refs.relatedPersonAddressForm && this.$refs.relatedPersonContactForm) {

          for (let index = 0; index < this.selectedChild.relatedPersons.length; index++) {
            if (this.$refs.relatedPersonForm[index] && this.$refs.relatedPersonAddressForm[index] && this.$refs.relatedPersonContactForm[index]) {
              this.$refs.relatedPersonForm[index].validatePerson();
              this.$refs.relatedPersonAddressForm[index].validateAddress();
              this.$refs.relatedPersonContactForm[index].validateContact();

              if (this.$refs.relatedPersonForm[index].invalid ||
                this.$refs.relatedPersonAddressForm[index].invalid ||
                this.$refs.relatedPersonContactForm[index].invalid) {
                jQuery('#childTabsId .md-tabs-navigation button:nth-child(' + (index + 2) + ')').trigger('click');
                this.invalid = true;
                return;
              }
            }
          }
        }
      },
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },
    },
  }
</script>

<style lang="scss" scoped>
  h5 {
    font-weight: 400;
    color: lightseagreen;
  }

  hr {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
    background: darkseagreen;
  }

  .md-tab {
    padding-left: 8px;
    padding-right: 8px;
  }



  .label {
    font-size: x-large;
  }

  .label.medium {
    font-size: medium;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }

  .badge {
    width: 19px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    right: 2px;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.05em;
  }
</style>
