<template>
  <div v-if="isAuthenticated">
    <div class="grid-x grid-padding-x">
      <div class="cell callout success">
        <h3><i class="fi-upload"></i>&nbsp;&nbsp;&nbsp;Daten-Import für Einrichtung
        &nbsp;<span v-if="selectedFacility" style="color: cornflowerblue; font-weight: normal;">{{selectedFacility.name}}</span></h3>
      </div>
    </div>

    <div v-if="organization && organization.facilities && organization.facilities .length <= 0"
         class="grid-x grid-padding-x grid-padding-y">
      <NoFacilityCard functionTitle="Daten-Importe"></NoFacilityCard>
    </div>
    <div v-else class="grid-x grid-padding-x grid-padding-y">
      <div class="cell">
        <ExcelDataImportForm></ExcelDataImportForm>
      </div>
    </div>
  </div>
</template>

<script>
  import ExcelDataImportForm from "../components/forms/ExcelDataImportForm";
  import NoFacilityCard from "../components/cards/NoFacilityCard";

  export default {
    name: 'FacilityImport',
    components: {
      ExcelDataImportForm,
      NoFacilityCard,
    },

    computed: {
      selectedFacility() {
        return this.$store.getters.selectedFacility;
      },

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      organization() {
        return this.$store.getters.organization;
      },
    }
  }
</script>

<style lang="scss" scoped>
  h5 {
    font-weight: 300;
    margin-bottom: 0;
  }

  i {
    color: black;
  }
</style>