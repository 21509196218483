<template>
  <div>
    <md-steppers id="excelImportStepperId" :md-active-step.sync="activeStep" md-linear :md-dynamic-height="true">
      <md-step id="firstStep" md-label="Datei auswählen" md-description="EXCEL-Datei und Startposition festlegen"
               :md-done.sync="firstStep">

        <div class="grid-x">
          <div class="cell large-6 medium-12 small-12">
            <p><b class="md-title">Daten-Import aus eigener EXCEL-Datei</b></p>
            <p style="text-align: justify">Hier haben Sie die Möglichkeit Ihre Daten initial aus einer eigenen
              EXCEL-Datei zu importieren.
              Dies ermöglicht ihnen einen leichten Einstieg für die Nutzung von <b>SoSE</b>.<br><br>
              Bitte beachten Sie, dass Sie hierzu eine EXCEL-Datei benötigen, welche entweder durch einen Datenexport
              aus einem anderen System erstellt werden kann
              oder die Sie selbst bereits erstellt und gepflegt haben.<br><br>
              Legen Sie im ersten Schritt zunächst die Start-Zelle fest und wählen Sie dann Ihre EXCEL-Datei. Die Datei
              wird dann eingelesen und analysiert.<br><br>
              <b>Hinweis</b><br>
              Der Import-Vorgang kann jederzeit unterbrochen und zu einem späteren Zeitpunkt fortgesetzt werden.
              Bereits vorgenommene Feld-Zuweisungen in Schritt 2 werden automatisch gespeichert und stehen bei
              Fortsetzung der Bearbeitung wieder zur Verfügung, sofern nicht in Schritt 1 eine Excel-Datei wieder neu ausgewählt wird.
            </p>
            <br>
          </div>
          <div class="cell large-6 medium-12 small-12">
            <div class="grid-x grid-padding-x">
              <div class="cell large-1 hide-for-medium-only hide-for-small"></div>
              <div class="cell large-6 medium-7">
                <md-field>
                  <label>Daten-Import für Einrichtung</label>
                  <md-select v-model="selectedFacilityReferenceNumber"
                             :disabled="sending || allowedFacilities.length < 1"
                             @md-selected="onSelectFacility">
                    <md-option v-for="facility of allowedFacilities" :value="facility.referenceNumber"
                               :key="facility.id">
                      {{facility.name}}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="cell large-5 medium-5 hide-for-small"></div>
            </div>
            <div v-if="selectedFacility" class="grid-x grid-padding-x">
              <div class="cell large-1 hide-for-medium-only hide-for-small"></div>
              <div class="cell large-3 medium-3 small-3">
                <md-field>
                  <label>Start-Zelle</label>
                  <md-input autocomplete="off" v-model="startCell"
                            :disabled="sending" @keyup="buildUploadUrl"/>
                  <md-tooltip>Geben Sie hier die erste Zelle (z.B. A1) in Ihrer EXCEL-Datei an, ab der die zu importierenden Daten (inkl. Spalten-Überschriften) beginnen.</md-tooltip>
                </md-field>
              </div>
              <div class="cell hide-for-large medium-1 hide-for-small-only"></div>
              <div class="cell small-3 medium-3 large-3 text-right">
                <md-checkbox v-model="autoAssign" class="md-primary" style="padding-top: 1rem;" @change="buildUploadUrl">auto<span class="hide-for-small-only">matisch</span>
                  <md-tooltip>Automatische Zuordnung von Spalten zu SoSE-Feldern <b v-if="autoAssign" style="color: greenyellow">eingeschalten</b><b v-else style="color: lightgray">ausgeschalten</b><br>Verwenden Sie diese Option, wenn SoSE einen Zuordungsvorschlag erstellen soll.</md-tooltip>
                </md-checkbox>
              </div>
              <div class="cell small-6 medium-5 large-5 text-right">
                <br>
                <div v-if="startCell.length < 2">
                  <button disabled class="button secondary" type="button">{{options.dictDefaultMessage}}
                  </button>
                </div>
                <div v-else @click="onVdropzoneClick()">
                  <vue-dropzone v-if="options.url" id="ownExcelFileDropzone"
                                :options="options"
                                @vdropzone-error="onVdropzoneError"
                                @vdropzone-success="onVdropzoneSuccess"
                                @vdropzone-mounted="onVdropzoneMounted"
                                @vdropzone-processing="onVdropzoneProcessing"
                                :disabled="sending || startCell.length < 2">
                  </vue-dropzone>
                </div>
              </div>
              <div class="cell large-1 hide-for-medium-only hide-for-small"></div>
              <div class="cell large-11 medium-12 small-12" style="height: 1rem;">
                <md-progress-bar v-if="sending" md-mode="indeterminate"></md-progress-bar>
              </div>
            </div>
            <div v-if="initialExcelLoadSuccess && !sending" class="grid-x grid-padding-x">
              <div class="cell small-0 medium-0 large-1"></div>
              <div class="cell small-12 medium-12 large-11">
                <md-card style="background: rgba(0,128,0,0.31)">
                  <md-card-header>
                    <div class="md-title">
                      <md-icon class="md-size-2x" style="color: seagreen">check</md-icon>
                      EXCEL-Datei Ok
                    </div>
                  </md-card-header>

                  <md-card-content v-if="uploadFileName && uploadDate">
                    Ihre EXCEL-Datei <b><i>{{uploadFileName}}</i></b> wurde erfolgreich am
                    <b><i>{{uploadDate | moment('LL')}}</i></b> um
                    <b><i>{{uploadDate | moment('LTS')}}</i></b> mit der Start-Zelle
                    <b><i>{{analyticsResult.headerStartCell}}</i></b> eingelesen und für Einrichtung
                    <b><i>{{selectedFacility.name}}</i></b> analysiert.
                    Wählen Sie jetzt ihre Daten aus.
                  </md-card-content>

                </md-card>
              </div>
            </div>
            <div v-if="initialExcelLoadFailure && !sending" class="grid-x grid-padding-x">
              <div class="cell small-0 medium-0 large-1"></div>
              <div class="cell small-12 medium-12 large-11">
                <md-card style="background: rgba(255,0,0,0.5);">
                  <md-card-header>
                    <div class="md-title">
                      <md-icon class="md-size-2x" style="color: red;">error_outline</md-icon>
                      Fehler
                    </div>
                  </md-card-header>

                  <md-card-content>
                    Ihre EXCEL-Datei konnte nicht eingelesen und analysiert werden.
                  </md-card-content>

                </md-card>
              </div>
            </div>
          </div>
          <div class="cell small-12 medium-12 large-12 text-right">
            <br><br>
            <button class="button success" @click="setDone('firstStep', 'secondStep')"
                    :disabled="!initialExcelLoadSuccess || sending">Daten auswählen &#9654;
            </button>
          </div>
        </div>
      </md-step>

      <md-step id="secondStep" md-label="Daten auswählen" md-description="Datenfelder auswählen und zuweisen"
               :md-error="secondStepStepError" :md-done.sync="secondStep">
        <div v-if="analyticsResult" class="grid-x grid-padding-x grid-padding-y">
          <div class="cell large-8 medium-8 small-12">
            <md-table v-model="searched" md-sort="headerName" md-sort-order="asc" md-card md-fixed-header
                      @md-selected="onSelectSourceHeader" :md-selected-value.sync="selectedRows"
                      style="height: 400px; min-height: 400px; max-height: 400px;">
              <md-table-toolbar>
                <div class="md-toolbar-section-start grid-x grid-padding-x">
                  <div class="cell large-4 medium-6 small-6">
                    <p class="md-title ai-number">
                      <span class="table-title">{{searched.length}} Spalten</span>
                    </p>
                  </div>
                  <div class="cell large-4 medium-6 small-6">
                    <md-checkbox v-model="showSelectionOnly" @change="onSearchOnTable" class="md-primary">
                      {{targetTypeSelectionCount}} Zuweisungen
                      <md-tooltip>Nur Zuweisung anzeigen</md-tooltip>
                    </md-checkbox>
                  </div>
                  <div class="cell large-4 medium-12 small-12">
                    <md-field md-clearable >
                      <md-input placeholder="Suche Spalten-Name ..." v-model="search" @input="onSearchOnTable"/>
                    </md-field>
                  </div>
                </div>
              </md-table-toolbar>

              <md-table-empty-state
                  md-label="Keine Spalte gefunden"
                  :md-description="`Keine Spalte mit dem Suchbegriff '${search}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
              </md-table-empty-state>

              <md-table-row :id="item.col" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single"
                            style="width: 400px; min-width: 400px; max-width: 400px;">
                <md-table-cell md-label="Spalten-Nr" md-sort-by="col" md-numeric>{{ item.col }}</md-table-cell>
                <md-table-cell md-label="Spalten-Name" md-sort-by="headerName">{{ item.headerName }}<md-tooltip>Klicken um Felder auszuwählen</md-tooltip></md-table-cell>
                <md-table-cell md-label="SoSE-Felder" style="padding: 0; margin: 0; width: 70%">
                  <table>
                    <tr>
                      <td style="vertical-align: middle; horiz-align: left; padding: 0px 5px; margin: 0; border: 0; width: 1%;" v-for="tt of item.targetTypes" :key="tt.label">
                        <md-chip v-if="tt.label !== 'IGNORE'" class="md-primary" @md-delete="onDeleteTargetTypes(item, tt)" md-deletable md-clickable>{{tt.targetText}}
                          <md-tooltip>Ziel-Format: <b>{{tt.targetFormat}}</b></md-tooltip>
                        </md-chip>
                        <md-chip v-else-if="item.targetTypes.length <= 1" class="md-secondary" md-clickable>
                          <i>{{tt.targetText.replace(' ', '&nbsp;')}}</i>
                        </md-chip>
                      </td>
                      <td style="border: 0; width: 100%">&nbsp;</td>
                    </tr>
                  </table>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
          <div class="cell large-4 medium-4 small-12">
            <md-table v-if="selectedItem" v-model="searchedAvailableTargetTypes" md-sort="targetText" md-sort-order="asc"
                      md-card md-fixed-header @md-selected="onSelectTargetTypes" :md-selected-value.sync="selectedTargetRows"
                      style="height: 400px; min-height: 400px; max-height: 400px;">
              <md-table-toolbar>
                <div class="md-toolbar-section-start grid-x grid-padding-x">
                  <div class="cell large-7 medium-12 small-12">
                    <p class="md-title ai-number">
                      <span class="table-title">{{searchedAvailableTargetTypes.length}}</span>
                      <span style="font-size: medium; font-weight: 300;">&nbsp;von {{analyticsResult.targetTypes.length - 1}} verfügbar</span>
                    </p>
                  </div>
                  <div class="cell large-5 medium-12 small-12">
                    <md-field md-clearable >
                      <md-input placeholder="Suche Feld ..." v-model="searchSoSEField" @input="searchOnTable"/>
                    </md-field>
                  </div>
                </div>
              </md-table-toolbar>

              <md-table-empty-state
                  md-label="Kein Feld gefunden"
                  :md-description="`Kein Feld mit dem Suchbegriff '${searchSoSEField}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
              </md-table-empty-state>

              <md-table-row :id="item.label" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="multiple"
                            style="width: 400px; min-width: 400px; max-width: 400px;">
                <md-table-cell style="padding-right: 32px !important; padding-left: 16px !important;" md-label="Name" md-sort-by="targetText">{{ item.targetText }}
                  <md-tooltip>Ziel-Format: <b>{{item.targetFormat}}</b></md-tooltip>
                </md-table-cell>
              </md-table-row>
            </md-table>
            <md-card v-else style="height: 400px; min-height: 400px; max-height: 400px;">
              <md-card-header>
                <h5><p><i>Bitte wählen Sie in der linken Tabelle eine Zeile aus und erstellen dann hierfür die Zuweisungen.</i></p></h5>
              </md-card-header>
            </md-card>
          </div>
        </div>
        <br>
        <div class="grid-x grid-padding-x">
          <div class="cell small-3 medium-4 text-center">
            <label>Alle bereits vorhandenen Kinder vor dem Import löschen</label>
            <md-switch v-model="deleteAllExistingChilds">{{booleanText(deleteAllExistingChilds)}}</md-switch>
          </div>
          <div class="cell small-3 medium-4 text-center">
            <label v-if="!deleteAllExistingChilds">Bereits vorhandene Kinder überspringen</label>
            <label v-else style="color: darkgrey"><i>Bereits vorhandene Kinder überspringen</i></label>
            <md-radio v-if="analyticsResult" v-model="analyticsResult.skipExistingChilds" value="true" class="md-primary" :disabled="deleteAllExistingChilds">Ja</md-radio>
            <md-radio v-if="analyticsResult" v-model="analyticsResult.skipExistingChilds" value="false" class="md-primary" :disabled="deleteAllExistingChilds">Nein</md-radio>
            <md-tooltip><span v-if="!deleteAllExistingChilds && analyticsResult && analyticsResult.skipExistingChilds === 'undefined'">Bitte wählen Sie eine Option</span></md-tooltip>
          </div>
          <div class="cell small-3 medium-2">
            <md-field v-if="analyticsResult" style="width: 5.5rem;">
              <label>Start-Zeilen-Nr</label>
              <md-input type="number" name="startRow" id="startRow" autocomplete="off" v-model="analyticsResult.startRow"/>
              <md-tooltip>Geben Sie hier die Zeilennummer in der EXCEL-Datei an, ab welcher der Import beginnen soll.</md-tooltip>
            </md-field>
          </div>
          <div class="cell small-3 medium-2">
            <md-field v-if="analyticsResult" style="width: 5.5rem;">
              <label>End-Zeilen-Nr</label>
              <md-input type="number" name="endRow" id="endRow" autocomplete="off" v-model="analyticsResult.endRow"/>
              <md-tooltip>Geben Sie hier die Zeilennummer in der EXCEL-Datei an, an welcher der Import enden soll. Diese muss min. größer oder gleich der Start-Zeilen-Nr sein.</md-tooltip>
            </md-field>
          </div>
        </div>
        <div class="grid-x">
          <div class="cell small-6 text-left">
            <button class="button success" @click="resetDone('secondStep', 'firstStep')">&#9664; Zurück zur
              Dateiauswahl
            </button>
          </div>
          <div class="cell small-6 text-right">
            <button v-if="analyticsResult" class="button success" @click="setDone('secondStep', 'thirdStep')" :disabled="!(analyticsResult.startRow > 1 && analyticsResult.endRow >= analyticsResult.startRow) || (!deleteAllExistingChilds && analyticsResult.skipExistingChilds === 'undefined') || sending">Konfiguration bestätigen
              &#9654;
            </button>
          </div>
        </div>
      </md-step>

      <md-step id="thirdStep" md-label="Import durchführen" md-description="Auswahl überprüfen und Import starten"
               :md-done.sync="thirdStep">

        <div v-if="!finalConfirmation" class="grid-x grid-padding-x">
          <div class="cell small-12 medium-6 large-6">
            <md-table v-if="searchedImportResultChildren && !sending" id="searchedImportResultChildrenId"
                      v-model="searchedImportResultChildren" md-sort="numMessages"
                      md-sort-order="desc" md-card md-fixed-header
                      @md-selected="onSelectChild" style="height: 540px;">
              <md-table-toolbar>
                <div class="md-toolbar-section-start">
                  <p class="md-title ai-number">
                    <span class="table-title">{{searchedImportResultChildren.length}} Kinder</span>
                  </p>
                </div>

                <md-field md-clearable class="md-toolbar-section-end">
                  <md-input placeholder="Suche Nachname..." v-model="searchResultChild" @input="searchOnImportResult" />
                </md-field>
              </md-table-toolbar>

              <md-table-empty-state
                  md-label="Kein Kind gefunden"
                  :md-description="`Kein Kind mit dem Suchbegriff '${searchResultChild}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
              </md-table-empty-state>

              <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="single" :class="getClass(item)">
                <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Gen" md-sort-by="masterdata.sex">
                  <span v-if="item.masterdata.sex === 'MALE'" :class="item.masterdata.sex">&#9794;</span>
                  <span v-else-if="item.masterdata.sex === 'FEMALE'" :class="item.masterdata.sex">&#9792;</span>
                  <span v-else-if="item.masterdata.sex === 'DIVERSE'" :class="item.masterdata.sex">&#9954;</span>
                </md-table-cell>
                <md-table-cell md-label="Vorname" md-sort-by="masterdata.firstname">{{item.masterdata.firstname }}</md-table-cell>
                <md-table-cell md-label="Nachname" md-sort-by="masterdata.lastname">{{ item.masterdata.lastname }}</md-table-cell>
                <md-table-cell md-label="Alter" md-sort-by="age">{{ item.age }}</md-table-cell>
                <md-table-cell md-label="Probleme" md-sort-by="numMessages">
                  <md-icon v-if="item.numProblems > 0" style="color: orangered">error_outline
                    <md-tooltip v-html="item.problemsMessage"></md-tooltip>
                  </md-icon>
                  <md-icon v-else style="color: seagreen">check
                    <md-tooltip>Import möglich</md-tooltip>
                  </md-icon>
                  <md-icon v-if="item.numHints > 0" style="color: cornflowerblue">info_outline
                    <md-tooltip v-html="item.hintsMessage"></md-tooltip>
                  </md-icon>
                </md-table-cell>
              </md-table-row>
            </md-table>
            <md-card v-else style="height: 540px; min-height: 540px; max-height: 540px;">
              <md-card-header>
                <h5><p><i>Die Konfiguration wird überprüft...</i></p></h5>
              </md-card-header>
              <md-card-content>
                <md-progress-bar md-mode="indeterminate"></md-progress-bar>
              </md-card-content>
            </md-card>
          </div>
          <div class="cell small-12 medium-6 large-6">
            <md-card style="height: 540px;">
              <md-card-header v-if="!selectedChild">
                <h5><p><i>Bitte wählen Sie in der linken Tabelle ein Kind aus und überprüfen Sie das mögliche Zuweisungsergebnis.</i></p></h5>
              </md-card-header>
              <md-card-content v-else>
                <ChildTabs ref="childTabsForm"
                           :mdCardContentStyle="'height: 415px; overflow-x: auto; padding: 0; margin-top: 0;'"
                           :mode="'update'"
                           :selectedChild="selectedChild"
                           :editActive="false"
                           :sending="sending"></ChildTabs>
              </md-card-content>
            </md-card>
          </div>
        </div>

        <br>
        <div v-if="!finalConfirmation" class="grid-x">
          <div class="cell small-0 medium-2 large-3">
          </div>
          <div class="cell small-12 medium-8 large-6 text-center">
            <div v-if="importResult" :hidden="sending">

              <div v-if="!importResult.success" class="grid-x grid-padding-x">
                <div class="cell large-3 medium-3 small-3">
                  <md-field>
                    <label>Start-Zelle</label>
                    <md-input autocomplete="off" v-model="startCell"
                              :disabled="sending" @keyup="buildUploadUrl"/>
                    <md-tooltip>Geben Sie hier die erste Zelle (z.B. A1) in Ihrer EXCEL-Datei an, ab der die zu importierenden Daten (inkl. Spalten-Überschriften) beginnen.</md-tooltip>
                  </md-field>
                </div>
                <div class="cell large-6 medium-6 small-6">
                  <br>
                  <div v-if="startCell.length < 2">
                    <button disabled class="button secondary" type="button">{{options.dictDefaultMessage}}
                    </button>
                  </div>
                  <div v-else @click="onVdropzoneClick()">
                    <vue-dropzone v-if="options.url" id="ownExcelFileReloadDropzone"
                                  :options="options"
                                  @vdropzone-error="onVdropzoneError"
                                  @vdropzone-success="onVdropzoneReloadSuccess"
                                  @vdropzone-mounted="onVdropzoneMounted"
                                  @vdropzone-processing="onVdropzoneProcessing"
                                  :disabled="sending || startCell.length < 2">
                    </vue-dropzone>
                    <md-tooltip>Sie können Ihre EXCEL-Datei nach entsprechenden Korrekturen hier erneut einlesen und überprüfen lassen.</md-tooltip>
                  </div>
                </div>
                <div class="cell large-3 medium-3 small-3"></div>
                <br>
              </div>

              <md-card v-if="importResult.success" style="background: rgba(0,128,0,0.31)">
                <md-card-header>
                  <div class="md-title">
                    <md-icon class="md-size-2x" style="color: seagreen">check</md-icon>
                    Prüfung OK
                  </div>
                </md-card-header>

                <md-card-content class="text-justify">
                  Die zugewiesenen Daten aus Ihrer EXCEL-Datei würden wie oben dargestellt importiert werden.<br><br>
                  Bitte <b>überprüfen</b> Sie das Ergebnis <b>bevor Sie den Import durchführen</b>!
                </md-card-content>
              </md-card>
              <md-card v-else style="background: orange">
                <md-card-header>
                  <div class="md-title">
                    <md-icon class="md-size-2x" style="color: orangered;">error_outline</md-icon>
                    IMPORT PROBLEME
                  </div>
                </md-card-header>

                <md-card-content class="text-justify">
                  Die zugewiesenen Daten aus Ihrer EXCEL-Datei würden wie oben dargestellt importiert werden.<br><br>
                  Die Prüfung hat <b>{{importResult.numProblems}} Problem(e)</b> für den Import festgestellt.
                  Diese werden oben in der Tabelle entsprechend angezeigt. Führen Sie hierzu den Mauszeiger über das jeweilige Warnzeichen.
                  <br>
                  Bitte überprüfen Sie das Ergebnis und <b>lösen Sie die Probleme bevor Sie den Import durchführen</b>!
                  <br><br>
                  <b>HINWEIS</b><br>
                  Sie können Ihre EXCEL-Datei <i><b>{{uploadFileName}}</b></i> nach entsprechenden Korrekturen, welche die Probleme beheben, direkt in diesem Schritt
                  erneut einlesen und überprüfen lassen.
                </md-card-content>
              </md-card>
            </div>
          </div>
          <div class="cell small-0 medium-2 large-3">
          </div>
        </div>
        <div v-else class="grid-x">
          <div class="cell small-0 medium-1 large-3"></div>
          <div class="cell small-12 medium-10 large-6 text-center">
            <md-card style="min-height: 400px;">
              <md-card-header v-if="!importPhase3">
                <div v-if="importResult.success" class="md-title">
                  <md-icon class="md-size-2x" style="color: seagreen">check</md-icon>
                  Alles in Ordnung
                </div>
                <button  v-else class="md-title button secondary" style="padding: 0.5rem 1rem;" @click="finalConfirmation = false">
                  <md-icon class="md-size-2x" style="color: orangered">error_outline</md-icon>
                  ACHTUNG: Import Probleme
                  <md-tooltip style="color: yellow">Es bestehen immer noch Probleme. <b>Bitte lösen Sie diese Probleme vor dem Import!</b></md-tooltip>
                </button>
              </md-card-header>
              <md-card-header v-else>
                <div v-if="sending" class="md-title">
                  <br><br>
                  <md-icon class="md-size-2x" style="color: seagreen">cloud_upload</md-icon>
                  &nbsp;&nbsp;&nbsp;Daten werden importiert ...
                </div>
                <div v-else-if="importErrorMsg" class="md-title">
                  <br><br>
                  <md-icon class="md-size-2x" style="color: orangered">error_outline</md-icon>
                  Import Fehlgeschlagen<br><br><br>
                  <p style="color: orangered; font-size: large;">FEHLER: {{importErrorMsg}}</p><br><br>
                  <button class="button alert" @click="finalConfirmation = false, importPhase3 = false" :disabled="sending">&#9664; Zurück zur Überprüfung
                  </button>
                </div>
                <div v-else class="md-title">
                  <br><br><br>
                  <md-icon class="md-size-2x" style="color: seagreen">done_all</md-icon>&nbsp;&nbsp;&nbsp;
                  Ihre Daten wurden erfolgreich importiert.
                  <br><br><br>
                  <button class="button success" @click="onOpenChildren"><img width="20px" src="../../assets/icons/children-playing.png"/>&nbsp;&nbsp;&nbsp;Hier gehts zu den Kindern</button>
                </div>
              </md-card-header>
              <md-card-content v-if="!importPhase3" class="text-justify">
                <br>
                <label>
                  <span v-if="importResult.numProblems > 0">Anzahl Probleme: <b style="color: red;">{{importResult.numProblems}}</b></span>
                  <span v-if="importResult.numHints > 0">&nbsp;&nbsp;&nbsp;Anzahl Hinweise: <b style="color: cornflowerblue;">{{importResult.numHints}}</b></span>
                  <br>
                  Alle bereits vorhandenen Kinder vor dem Import löschen: <b style="color: seagreen">{{booleanText(importResult.deleteAllExistingChilds)}}</b><br>
                  Bereits vorhandene Kinder überspringen: <b style="color: seagreen">{{booleanText(importResult.skipExistingChilds)}}</b><br>
                  <br>
                  Sie können den Import jetzt starten.
                  <span v-if="importResult.numProblems > 0">
                  Aufgrund der noch bestehenden Probleme kann das System entweder den Import ablehnen,
                  oder aber auch durchführen mit Ergebnissen, die mit dieser Konfiguration gewünscht und möglich sind.
                  </span>
                  <span v-else><br><br><br><br><br></span>
                </label>

                <br>
                <div class="grid-x">
                  <div class="cell small-6 text-left">
                    <button class="button alert" @click="finalConfirmation = false" :disabled="sending">&#9664; Abrechen und zurück
                    </button>
                  </div>
                  <div class="cell small-6 text-right">
                    <button v-if="analyticsResult" class="button success" @click="onImport" :disabled="sending">Import jetzt durchführen
                      &#9654;
                      <md-tooltip>
                        <span v-if="importResult && importResult.importProblems.length > 0">Führen Sie den Import nur durch, wenn Sie sich <b>absolut sicher</b> sind!</span>
                        <span v-else>Sieht gut aus! Jetzt kann es los gehen.</span>
                      </md-tooltip>
                    </button>
                  </div>
                </div>
              </md-card-content>
              <md-card-content v-else class="text-center">
                <br><br><br><br><br>
                <md-progress-bar v-if="sending" md-mode="indeterminate"></md-progress-bar>
              </md-card-content>
            </md-card>
          </div>
          <div class="cell small-0 medium-1 large-3"></div>
        </div>
        <br>
        <div v-if="!finalConfirmation" class="grid-x">
          <div class="cell small-6 text-left">
            <button class="button success" @click="resetDone('thirdStep', 'secondStep')" :disabled="sending">&#9664; Zurück zur
              Konfiguration
            </button>
          </div>
          <div class="cell small-6 text-right">
            <button v-if="analyticsResult" class="button success" @click="setDone('', 'thirdStep')" :disabled="analyticsResult.numRowsToRead < 1 || sending">Import durchführen
              &#9654;
              <md-tooltip>Klicken Sie hier für die abschließende Import-Zusammenfassung.
                <span v-if="importResult && importResult.importProblems.length > 0" style="color: yellow">Es gibt noch Import-Probleme. <b>Bitte lösen Sie die Probleme bevor Sie den Import durchführen!</b></span>
              </md-tooltip>
            </button>
          </div>
        </div>

      </md-step>
    </md-steppers>
  </div>
</template>


<script>
  import Vue from 'vue'
  import jQuery from 'jquery';
  import vue2Dropzone from 'vue2-dropzone';
  import Spinner from 'vue-simple-spinner';
  import HttpErrorHandler from '../../services/HttpErrorHandler';
  import FacilityService from '../../services/FacilityService';
  import ChildTabs from '../../components/tabs/ChildTabs';
  import HttpInterceptor from "../../services/HttpInterceptor";

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchByHeaderName = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.headerName).includes(toLower(term)));
    }

    return items;
  };

  const searchByChildLastName = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.masterdata.lastname).includes(toLower(term)));
    }

    return items;
  };

  const searchBySelection = (items, showSelectionOnly) => {
    if (showSelectionOnly) {
      return items.filter(item => {
        if (item.targetTypes) {
          if (item.targetTypes.length === 1) {
            if (item.targetTypes[0].label !== 'IGNORE') {
              return true;
            }
          } else if (item.targetTypes.length > 1) {
            return true;
          }
        }
        return false;
      })
    }
    return items;
  };

  const searchByTargetText = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.targetText).includes(toLower(term)));
    }

    return items;
  };

  export default {
    name: 'ExcelDataImportForm',

    mounted() {
      this.restoreUserSettings();
      this.initData();
    },

    components: {
      vueDropzone: vue2Dropzone,
      'vue-simple-spinner': Spinner,
      ChildTabs,
    },

    data: () => ({
      sending: false,

      finalConfirmation: false,
      importPhase3: false,
      importErrorMsg: null,
      deleteAllExistingChilds: false,

      initialExcelLoadSuccess: false,
      initialExcelLoadFailure: false,
      startCell: '',
      options: {
        url: '',
        maxFilesize: 10.0, // 1024 KB
        headers: {
          'accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': 'Bearer ' + sessionStorage.getItem('sose.access_token')
        },
        createImageThumbnails: false,
        thumbnailWidth: 250,
        thumbnailHeight: 50,
        previewTemplate: '<div class="dz-preview dz-file-preview"></div>',
        dictDefaultMessage: 'EXCEL-Datei auswählen',
        acceptedFiles: '.xls, .xlsx',
      },

      analyticsResult: null,
      importResult: null,

      importResultChildren: null,

      uploadFileName: null,
      uploadDate: null,

      activeStep: 'firstStep',
      firstStep: false,
      secondStep: false,
      thirdStep: false,
      secondStepStepError: null,

      search: '',
      searchSoSEField: '',
      searchResultChild: '',
      searched: [],
      searchedAvailableTargetTypes: [],
      searchedImportResultChildren: [],

      selectedItem: null,
      selectedRows: [],
      selectedTargetRows: [],
      selectedTargetType: {},
      selectedTargetTypes: [],
      availableTargetTypes: [],
      targetTypeSelectorOpen: false,
      targetTypeSelectionCount: 0,
      showSelectionOnly: false,
      autoAssign: true,
      canUpdateTargetTypeSelection: false,

      selectedChild: null,
      sourceHeaderSelectionDisabled: false,

      selectedFacility: null,
      selectedFacilityReferenceNumber: '',
    }),

    methods: {

      restoreUserSettings() {
        if (this.dataImportFacility) {
          this.selectedFacilityReferenceNumber = this.dataImportFacility.referenceNumber;
          this.$store.commit('dataImportFacility', null);
        }
        this.onSelectFacility();
      },

      initData() {
        if (this.selectedFacility) {
          setTimeout(() => this.sending = true, 10);
          setTimeout(() => {
            this.buildUploadUrl();
            this.restoreAnalyticResult();
            this.searchOnTable();
            this.sending = false;
          },500)
        }
      },

      buildUploadUrl() {
        this.options.url = '';
        setTimeout(() => this.options.url = this.$store.getters.serverUrl + 'facility/excel/import/phase1/' + this.selectedFacility.referenceNumber + '/' + this.startCell.toUpperCase() + '?autoAssign=' + this.autoAssign, 200);
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      booleanText(value) {
        return value ? 'ja' : 'nein';
      },

      booleanTextRaw(value) {
        if (value) {
          return 'true'
        } else {
          return 'false'
        }
      },

      onSelectFacility() {
        this.$store.commit('selectedFacilityReferenceNumber', this.selectedFacilityReferenceNumber);
        this.selectedFacility = this.$store.getters.selectedFacility;

        this.initData();
      },

      onSearchOnTable() {
        if (this.selectedItem) {
          jQuery('tr#' + this.selectedItem.col).trigger('click');
        }
        this.searchOnTable();
      },

      searchOnTable() {
        if (this.analyticsResult) {
          this.searched = this.analyticsResult.headerEntries;
          this.searched = searchByHeaderName(this.searched, this.search);
          this.searched = searchBySelection(this.searched, this.showSelectionOnly);

          this.searchedAvailableTargetTypes = this.availableTargetTypes;
          this.searchedAvailableTargetTypes = searchByTargetText(this.searchedAvailableTargetTypes, this.searchSoSEField);
        }
      },

      onSelectSourceHeader(item) {
        if (this.sourceHeaderSelectionDisabled) {
          this.sourceHeaderSelectionDisabled = false;
          return;
        }

        this.searchSoSEField = '';
        if (item && item.col) {
          this.canUpdateTargetTypeSelection = false;

          this.selectedTargetRows = [];
          this.selectedItem = item;
          this.availableTargetTypes = [];

          this.analyticsResult.targetTypes.forEach(tt => {
            let canAdd = true;
            this.analyticsResult.headerEntries.forEach(he => {
              he.targetTypes.forEach(stt => {
                if (stt.label === tt.label && he.col !== this.selectedItem.col) {
                  canAdd = false;
                }
              })
            });

            if (tt.label === 'IGNORE') {
              canAdd = false;
            }
            if (canAdd) {
              this.availableTargetTypes.push(tt);
            }
          });

          setTimeout(() => {
            this.analyticsResult.headerEntries.forEach(he => {
              he.targetTypes.forEach(stt => {
                this.availableTargetTypes.forEach(att => {
                  if (he.col === this.selectedItem.col && stt.label === att.label) {
                    jQuery('#' + att.label).find('.md-checkbox-container').trigger('click');
                  }
                });
              })
            });
            setTimeout(() => this.canUpdateTargetTypeSelection = true, 500);
          }, 100);

        } else {
          this.availableTargetTypes = [];
          this.selectedItem = null;
        }
        this.searchOnTable();
      },

      onSelectTargetTypes(items) {
        if (items && this.selectedItem !== null && this.canUpdateTargetTypeSelection) {
          this.analyticsResult.headerEntries.forEach(he => {
            if (he.col === this.selectedItem.col) {

              he.targetTypes = [];
              items.forEach(item => {
                he.targetTypes.push(item);
              });

              if (he.targetTypes.length <= 0) {
                this.analyticsResult.targetTypes.forEach(tt => {
                  if (tt.label === 'IGNORE') {
                    he.targetTypes.push(tt);
                  }
                });
              }
            }
          });
        }
        this.storeAnalyticResult();
      },

      onDeleteTargetTypes(item, targetType) {
        this.sourceHeaderSelectionDisabled = true;
        if (item && targetType) {
          this.selectedItem = item;
          let newSelectedTargetTypes = [];
          this.selectedItem.targetTypes.forEach(tt => {
            if (targetType.label !== tt.label) {
              newSelectedTargetTypes.push(tt)
            }
          });

          if (newSelectedTargetTypes.length <= 0) {
            this.analyticsResult.targetTypes.forEach(tt => {
              if (tt.label === 'IGNORE') {
                newSelectedTargetTypes.push(tt);
              }
            });
          }

          this.selectedItem.targetTypes = newSelectedTargetTypes;

          this.searchOnTable();
          this.storeAnalyticResult();

          if (this.selectedItem) {
            jQuery('tr#' + this.selectedItem.col).trigger('click');
          }
        }
      },

      storeAnalyticResult() {
        if (this.analyticsResult) {
          localStorage.setItem(this.user.md5 + '@' + this.selectedFacility.referenceNumber + '@dataImportAnalyticsResult', JSON.stringify(this.analyticsResult));
          this.countTargetTypeSelections();
        }
      },

      storeUploadFileProperties() {
        if (this.uploadFileName) {
          localStorage.setItem(this.user.md5 + '@' + this.selectedFacility.referenceNumber + '@dataImportUploadFileName', this.uploadFileName);
        }
        if (this.uploadDate) {
          localStorage.setItem(this.user.md5 + '@' + this.selectedFacility.referenceNumber + '@dataImportUploadDate', Vue.moment(this.uploadDate).toString());
        }
      },

      restoreAnalyticResult() {
        if (localStorage.getItem(this.user.md5 + '@' + this.selectedFacility.referenceNumber + '@dataImportAnalyticsResult')) {
          this.analyticsResult = JSON.parse(localStorage.getItem(this.user.md5 + '@' + this.selectedFacility.referenceNumber + '@dataImportAnalyticsResult'));
          this.startCell = this.analyticsResult.headerStartCell;
          this.initialExcelLoadSuccess = true;
          if (localStorage.getItem(this.user.md5 + '@' + this.selectedFacility.referenceNumber + '@dataImportUploadFileName')) {
            this.uploadFileName = localStorage.getItem(this.user.md5 + '@' + this.selectedFacility.referenceNumber + '@dataImportUploadFileName');
          }
          if (localStorage.getItem(this.user.md5 + '@' + this.selectedFacility.referenceNumber + '@dataImportUploadDate')) {
            this.uploadDate = Vue.moment(localStorage.getItem(this.user.md5 + '@' + this.selectedFacility.referenceNumber + '@dataImportUploadDate'));
          }

          this.countTargetTypeSelections();
        }
      },

      countTargetTypeSelections() {
        this.targetTypeSelectionCount = 0;
        if (this.analyticsResult) {
          this.analyticsResult.headerEntries.forEach(he => {
            if (he.targetTypes) {
              if (he.targetTypes.length === 1) {
                if (he.targetTypes[0].label !== 'IGNORE') {
                  this.targetTypeSelectionCount++;
                }
              } else {
                this.targetTypeSelectionCount += (he.targetTypes.length);
              }
            }
          });
        }
      },

      resetDone(id, index) {
        this[id] = false;

        // this.secondStepError = null

        if (index) {
          this.activeStep = index;
          this.doScrolling();
        }
      },

      setDone(id, index) {
        this[id] = true;

        this.secondStepStepError = null;

        if (index) {
          this.activeStep = index;
          this.doScrolling();
        }

        if (id === 'firstStep' && index === 'secondStep') {
          this.analyticsResult.deleteAllExistingChilds = this.booleanTextRaw(this.deleteAllExistingChilds);
          this.analyticsResult.skipExistingChilds = 'undefined';
          this.deleteAllExistingChilds = false;
        }

        if (id === 'secondStep' && index === 'thirdStep') {
          this.analyticsResult.deleteAllExistingChilds = this.booleanTextRaw(this.deleteAllExistingChilds);
          this.finalConfirmation = false;
          this.importPhase3 = false;

          if (this.analyticsResult.skipExistingChilds === 'undefined') {
            this.analyticsResult.skipExistingChilds = 'true';
          }
          this.importExcelPhase2();
        }

        if (id === '' && index === 'thirdStep') {
          this.finalConfirmation = true;
        }
      },

      doScrolling() {
        if (this.activeStep === 'firstStep') {
          window.scrollTo({top: 142, left: 0, behavior: 'smooth'})
        }
        if (this.activeStep === 'secondStep') {
          jQuery('table').removeAttr('style');

          setTimeout(() => jQuery('.md-table-head-container.md-ripple').children(":contains('Spalten-Nr')").trigger('click'), 200);
          setTimeout(() => jQuery('.md-table-head-container.md-ripple').children(":contains('Spalten-Name')").trigger('click'), 400);
          setTimeout(() => window.scrollTo({top: 142, left: 0, behavior: 'smooth'}), 600);
        }
        if (this.activeStep === 'thirdStep') {
          setTimeout(() => window.scrollTo({top: 142, left: 0, behavior: 'smooth'}), 100);
        }
      },

      onImport() {
        this.importPhase3 = true;
        this.importExcelPhase3();
      },

      importExcelPhase2() {
        this.importResult = null;
        this.importErrorMsg = null;
        this.sending = true;
        this.selectedChild = null;
        FacilityService.importExcelPhase2(this.selectedFacility.referenceNumber, this.analyticsResult)
          .then(response => {
            this.sending = false;
            this.importResult = response.data;
            if (this.importResult.success) {
              this.$store.commit('successMsg', 'Ihre Konfiguration wurde erfolgreich geprüft und der Import kann durchgeführt werden.');
            } else {
              this.$store.commit('warningMsg', 'Ihre Konfiguration wurde erfolgreich geprüft. Es gibt allerdings <b>' + this.importResult.importProblems.length + ' Problem(e) </b> für den Import.');
            }
            this.prepareImportResultChildren();
            this.searchOnImportResult();
            setTimeout(() => window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' }), 1000);
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler bei der Prüfung Ihrer Konfiguration.');
          })
      },

      importExcelPhase3() {
        this.sending = true;
        FacilityService.importExcelPhase3(this.importResult)
          .then(response => {
            if (response.data.success) {
              this.$store.commit('successMsg', 'Die ausgewählten Daten Ihrer EXCEL-Datei wurden erfolgreich importiert.');
            }
            this.sending = false;
          })
          .catch(e => {
            this.importErrorMsg = e.response.data.error + ' (Ursache: ' + e.response.data.message + ') ';
            if (this.$store.getters.labels &&
              this.$store.getters.labels.errorMessage &&
              this.$store.getters.labels.errorMessage[e.response.data.error]) {
              this.importErrorMsg = this.$store.getters.labels.errorMessage[e.response.data.error] + ' (' + e.response.data.message + ')';
            }
            HttpErrorHandler.handleError(e, this, 'Fehler beim Import der ausgewählten Daten Ihrer EXCEL-Datei.');
            this.sending = false;
          })
      },

      prepareImportResultChildren() {
        if (this.importResult && this.importResult.childs) {
          this.importResultChildren = [];
          let row = this.analyticsResult.startRow;
          this.importResult.numProblems = 0;
          this.importResult.numHints = 0;
          for (let child of this.importResult.childs) {
            let preparedChild = JSON.parse(JSON.stringify(child));

            preparedChild.numMessages = 0;
            preparedChild.numHints = 0;
            preparedChild.hintsMessage = '';
            preparedChild.numProblems = 0;
            preparedChild.problemsMessage = '';
            for (let problem of this.importResult.importProblems) {
              if (Number(problem.row) === row) {
                preparedChild.numMessages++;
                if (problem.problem === true) {
                  this.importResult.numProblems++;
                  preparedChild.numProblems++;
                  preparedChild.problemsMessage += preparedChild.numProblems + ') ' + problem.message + '<br>';
                  if (problem.cell !== null) {
                    preparedChild.problemsMessage += ' (Zelle <b style="color: yellow">' + problem.cell + '</b>, Zeile <b style="color: yellow">' + problem.row + '</b>)<br>';
                  }
                } else {
                  this.importResult.numHints++;
                  preparedChild.numHints++;
                  preparedChild.hintsMessage += preparedChild.numHints + ') ' + problem.message + '<br>';
                  if (problem.cell !== null) {
                    preparedChild.hintsMessage += ' (Zelle <b style="color: yellow">' + problem.cell + '</b>, Zeile <b style="color: yellow">' + problem.row + '</b>)<br>';
                  }
                }
              }
            }

            if (preparedChild.masterdata.firstname === null) {
              preparedChild.masterdata.firstname = '';
            }
            if (preparedChild.masterdata.lastname === null) {
              preparedChild.masterdata.lastname = '';
            }

            this.importResultChildren.push(preparedChild);
            row++;
          }
        }
      },

      searchOnImportResult() {
        if (this.importResultChildren) {
          this.searchedImportResultChildren = this.importResultChildren;

          this.searchedImportResultChildren = searchByChildLastName(this.searchedImportResultChildren, this.searchResultChild);
        }
      },

      onSelectChild(item) {
        if (item) {
          this.selectedChild = item;
        } else {
          this.selectedChild = null;
        }
      },

      onOpenChildren() {
        localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumber', this.selectedFacility.referenceNumber);
        this.$router.push('/Kinder');
      },

      onVdropzoneMounted() {
        jQuery('#ownExcelFileDropzone').removeClass().addClass('button');
        jQuery('#ownExcelFileReloadDropzone').removeClass().addClass('button');
      },

      onVdropzoneProcessing(file) {
        HttpInterceptor.doRefreshToken();
        this.options.headers.Authorization = 'Bearer ' + sessionStorage.getItem('sose.access_token');
        this.sending = file.processing;
        this.uploadFileName = file.upload.filename;
        this.uploadDate = Vue.moment();
        this.initialExcelLoadSuccess = false;
        this.initialExcelLoadFailure = false;
      },

      onVdropzoneClick() {
        HttpInterceptor.doRefreshToken();
        this.options.headers.Authorization = 'Bearer ' + sessionStorage.getItem('sose.access_token');
      },

      onVdropzoneError(file, message) {
        this.sending = false;
        this.initialExcelLoadFailure = true;
        let errMsg = 'Fehler beim Lesen der EXCEL-Datei: <b>' + file.upload.filename + '</b><br>';
        HttpErrorHandler.handleError({response: {data: message}}, this, errMsg);
      },

      onVdropzoneSuccess(file, response) {
        this.sending = false;
        if (response.success) {
          this.$store.commit('successMsg', 'Die EXCEL-Datei <b>' + file.upload.filename + '</b> wurde erfolgreich eingelesen und analysiert.');
          this.initialExcelLoadSuccess = true;
          this.analyticsResult = response;
          this.searchedAvailableTargetTypes = [];
          this.selectedTargetRows = [];
          this.selectedItem = null;
          this.showSelectionOnly = false;
          this.search = '';
          this.searchSoSEField = '';
          this.searchResultChild = '';
          this.storeAnalyticResult();
          this.storeUploadFileProperties();
          this.searchOnTable();
          setTimeout(() => this.setDone('firstStep', 'secondStep'), 5000);
        } else {
          this.initialExcelLoadFailure = true;
        }
      },

      onVdropzoneReloadSuccess(file, response) {
        this.sending = false;
        if (response.success) {
          this.$store.commit('successMsg', 'Die EXCEL-Datei <b>' + file.upload.filename + '</b> wurde erneut erfolgreich eingelesen und Ihre Konfiguration überprüft.');
          this.initialExcelLoadSuccess = true;
          this.storeUploadFileProperties();
          this.doScrolling();
          this.importExcelPhase2();
        }
      },
    },

    computed: {

      user() {
        return this.$store.getters.user;
      },

      labels() {
        return this.$store.getters.labels;
      },

      allowedFacilities() {
        return this.$store.getters.allowedFacilities;
      },

      dataImportFacility() {
        return this.$store.getters.dataImportFacility;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .button {
    margin-bottom: 0;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    max-width: 500px;
    white-space: normal;
  }

  .md-content-header-mapping {
    padding: 0;
    max-height: fit-content;
    overflow: auto;
  }

  .table-title {
    color: cornflowerblue;
  }

  .md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
    background: darkseagreen;
  }

  h5 {
    font-weight: 400;
    color: lightseagreen;
  }

  .md-ripple {
    z-index: 1;
  }

  .at-card-content {
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
  }
</style>

<style lang="scss" scoped>
  @import "../../../node_modules/vue-material/src/theme/engine";

  @include md-register-theme("green-card", (
      primary: md-get-palette-color(green, 500)
  ));

  @include md-register-theme("black-card", (
      primary: md-get-palette-color(black, 500)
  ));

  @include md-register-theme("white-card", (
      primary: md-get-palette-color(white, 500)
  ));

  @include md-register-theme("blue-card", (
      primary: md-get-palette-color(blue, 100)
  ));

  @include md-register-theme("grey-card", (
      primary: md-get-palette-color(grey, 100)
  ));

  @include md-register-theme("purple-card", (
      primary: md-get-palette-color(purple, 500)
  ));

  @include md-register-theme("orange-card", (
      primary: md-get-palette-color(orange, 500)
  ));

  @import "../../../node_modules/vue-material/src/base/theme";
  @import "../../../node_modules/vue-material/src/components/MdCard/theme";

  .md-tooltip {
    @include md-theme-component() {
      @include md-theme-property(color, text-primary, tooltip);
      @include md-theme-property(background-color, tooltip, background, .9);
    }
  }
</style>

