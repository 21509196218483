<template>
  <div v-if="child">
    <div v-if="insurance">
      <div v-if="child.healthInformation" class="grid-x grid-padding-x">
        <div class="cell large-6 medium-6 small-6">
          <md-field :class="getValidationClass('divisiontype')">
            <label>Versicherungsnehmer</label>
            <md-select v-model="child.healthInformation.insuranceHolderPersonId" :disabled="sending">
              <md-option v-for="relatedPerson of child.relatedPersons" :value="relatedPerson.id" :key="relatedPerson.id">
                {{relatedPerson.fullName}}
              </md-option>
            </md-select>
            <span class="md-error" v-if="!$v.child.divisiontype.required">Versicherungsnehmer ist notwendig</span>
            <md-tooltip v-if="!(child.relatedPersons && child.relatedPersons.length > 0)">
              Noch keine Bezugsperson angegeben. Der Versicherungsnehmer muss aus den Bezugspersonen ausgewählt werden.
            </md-tooltip>
          </md-field>
        </div>
        <div class="cell large-6 medium-6 small-6">
          <br>
          <md-checkbox class="md-primary" v-model="child.healthInformation.privateInsurance">privat versichert</md-checkbox>
        </div>
        <div class="cell large-6 medium-6 small-6">
          <md-field :class="getValidationClass('insuranceName')">
            <label>Name Krankenversicherung</label>
            <md-input name="insuranceName" id="insuranceName" autocomplete="off"
                      v-model="child.healthInformation.insuranceName" :disabled="sending"/>
            <span class="md-error" v-if="!$v.child.insuranceName.required">Krankenversicherung bitte angeben</span>
          </md-field>
        </div>
        <div class="cell large-6 medium-6 small-6">
          <md-field :class="getValidationClass('insuranceNumber')">
            <label>Versicherungsnummer</label>
            <md-input name="insuranceNumber" id="insuranceNumber" autocomplete="off"
                      v-model="child.healthInformation.insuranceNumber" :disabled="sending"/>
            <span class="md-error" v-if="!$v.child.insuranceNumber.required">Versicherungsnummer bitte angeben</span>
          </md-field>
        </div>
        <div class="cell">
          <ContactForm ref="insuranceContactForm" :contact="child.healthInformation.insuranceContact" :insurance="insurance" :sending="sending"></ContactForm>
        </div>
      </div>
    </div>
    <div v-if="kibig">
      <div class="grid-x grid-padding-x">
        <div class="cell large-6 medium-6 small-6">
          <md-field :class="getValidationClass('kibigId')">
            <label>Id</label>
            <md-input name="kibigId" id="kibigId" autocomplete="off"
                      v-model="child.kibigId" :disabled="sending"/>
          </md-field>
        </div>
        <div class="cell large-6 medium-6 small-6">
          <md-field :class="getValidationClass('kibigName')">
            <label>Name</label>
            <md-input name="kibigName" id="kibigName" autocomplete="off"
                      v-model="child.kibigName" :disabled="sending"/>
          </md-field>
        </div>
      </div>
    </div>
    <div v-if="compensation">
      <div class="grid-x grid-padding-x">
        <div class="cell large-6 medium-6 small-6">
          <md-field>
            <label>Jahr<span v-if="compensationYears.length > 1">e</span></label>
            <md-select v-model="selectedCompensationYears" :disabled="sending" @md-selected="onUpdateCompensationSelection()" multiple>
              <md-option v-for="year of compensationYears" :value="year" :key="child.id + year">
                {{year}}
              </md-option>
            </md-select>
            <md-tooltip>Jahr<span v-if="compensationYears.length > 1">e</span> für Beitragsersatz auswählen</md-tooltip>
          </md-field>
        </div>
        <div class="cell large-6 medium-6 small-6" v-for="year of selectedCompensationYears" :key="'selected-years-' + child.id + year">
          <md-field>
            <label>Monate in {{year}}</label>
            <md-select v-model="compensationMonthsValuesForYear[year]" :disabled="sending" @md-selected="onUpdateCompensationSelection()" multiple>
              <md-option v-for="month of compensationMonthsForYear[year]" :value="month" :key="child.id + year + month">
                {{month}}
              </md-option>
            </md-select>
            <md-tooltip>Monate für Beitragsersatz in <b>{{year}}</b> auswählen</md-tooltip>
          </md-field>
        </div>
      </div>
    </div>
    <div v-if="!(kibig || compensation || emergency || insurance)">
      <div class="grid-x grid-padding-x">
        <div class="cell large-6 medium-6 small-6">
          <md-field :class="getValidationClass('divisiontype')">
            <label>Bereich</label>
            <md-select v-model="child.divisiontype" autocomplete="off"
                       :disabled="sending || child.currentBookingTime !== null">
              <md-option v-for="divisiontype of labels.divisionTypes" :value="divisiontype" :key="divisiontype">
                {{labels.divisiontype[divisiontype]}}
              </md-option>
            </md-select>
            <span class="md-error" v-if="!$v.child.divisiontype.required">Bereich ist notwendig</span>
            <md-tooltip v-if="child.currentBookingTime !== null">
              Der Bereich wird aus der aktuell gültigen Buchungszeit<br>
              übernommen und kann daher hier nicht geändert werden.
            </md-tooltip>
          </md-field>
        </div>
        <div class="cell large-6 medium-6 small-6">

          <md-autocomplete id="child-promotion-community-name-id" v-model="child.promotionCommunityName" :md-options="promotionCommunities"
                           @md-changed="getPromotionCommunities" md-opened="getPromotionCommunities"
                           @md-selected="selectPromotionCommunity" :class="getValidationClass('promotionCommunityName')" disabled>
            <label>Förderkommune</label>

            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
              <md-highlight-text :md-term="term">{{ item.promotionCommunityName }}</md-highlight-text>
            </template>

            <template slot="md-autocomplete-empty" slot-scope="{ term }">
              <span v-if="term.length > 1 && !loading">"{{ term }}" nicht gefunden</span>
            </template>

            <span class="md-error" v-if="!$v.child.promotionCommunityName.required">Förderkommune ist notwendig</span>
          </md-autocomplete>

        </div>
      </div>
      <div v-if="child.divisiontype === 'HORT'" class="grid-x grid-padding-x">
        <div class="cell large-6 medium-6 small-6">
          <md-field>
            <label>Klassenstufe</label>
            <md-select v-model="child.klassenstufe" autocomplete="off" :disabled="sending">
              <md-option v-for="stufe of labels.klassenstufen" :value="stufe" :key="stufe">
                {{labels.klassenstufe[stufe]}}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="cell large-6 medium-6 small-6"></div>
      </div>
      <div class="md-layout">
        <div v-if="validEntryDate" class="md-layout-item" style="padding-right: 15px;">
          <md-datepicker id="entry-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="entryDate" md-immediately :disabled="sending"
                         :class="getValidationClass('entryDate')" :md-disabled-dates="disabledFirstDay">
            <label>Eintrittsdatum</label>
            <span class="md-error" v-if="$v.child.entryDate.$model === 'Invalid date'">Eintrittsdatum ist notwendig</span>
          </md-datepicker>
        </div>
        <div v-if="validExitDate" class="md-layout-item" style="padding-left: 15px;">
          <md-datepicker id="exit-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="exitDate" md-immediately :disabled="sending"
                         :md-disabled-dates="disabledLastDay">
            <label>Austrittsdatum</label>
          </md-datepicker>
        </div>
      </div>
      <br>
      <md-tabs md-elevation="1" style="height: 500px;">
        <template slot="md-tab" slot-scope="{ tab }">
          {{ tab.label }} <b class="badge" v-if="tab.data.badge">1</b>
          <md-tooltip>
            <div v-if="tab.data.badge">Einwilligung zur Datenverarbeitung für <b>{{tab.data.child.masterdata.fullName}}</b> fehlt!</div>
          </md-tooltip>
        </template>

        <md-tab md-label="Allgemein">
          <md-checkbox class="md-primary" v-model="child.handicapped">Behinderung</md-checkbox>
          <br>
          <div v-if="child.handicapped" class="grid-x grid-padding-x">
            <div class="cell small-6">
              <md-field :class="getValidationClass('handicapTypes')">
                <label>Behinderungen</label>
                <md-select v-model="child.handicapTypes" autocomplete="off" multiple
                           :disabled="sending">
                  <md-option v-for="handicapType of labels.handicapTypes" :value="handicapType" :key="handicapType">
                    {{labels.handicapType[handicapType]}}
                  </md-option>
                </md-select>
                <span class="md-error" v-if="!$v.child.divisiontype.required">Bereich ist notwendig</span>
              </md-field>
            </div>
            <div class="cell small-6">
              <md-field :class="getValidationClass('bezirkFachkraftstunden')">
                <label>Bezirk Fachkraftstunden</label>
                <md-select v-model="child.bezirkFachkraftstunden" autocomplete="off" :disabled="sending">
                  <md-option v-for="type of labels.bezirkFachkraftstundenTypes" :value="type" :key="type">
                    {{labels.bezirkFachkraftstundenType[type]}}
                  </md-option>
                </md-select>
                <span class="md-error" v-if="!$v.child.divisiontype.required">Bereich ist notwendig</span>
              </md-field>
            </div>
          </div>
          <md-checkbox class="md-primary" v-model="child.migrantBackground">Migrationshintergrund</md-checkbox>
          <br>
          <div class="grid-x grid-padding-x" :style="displayElement(child.migrantBackground)">
            <div class="cell large-4 medium-4 small-4">
              <div style="display: none">{{selectedCountryOfOrigin = labels.country[this.child.countryOfOrigin]}}</div>
              <md-autocomplete id="country-of-origin-id" v-model="selectedCountryOfOrigin" :md-options="countries"
                               @md-changed="getCountries" @md-opened="getCountries"
                               @md-selected="selectCountry">
                <label>Herkunftsland</label>

                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                  <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
                </template>

                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                  "{{ term }}" nicht gefunden
                </template>

              </md-autocomplete>
            </div>
            <div class="cell large-4 medium-4 small-4">
              <div style="display: none">{{selectedNativeLanguage = labels.language[this.child.nativeLanguage]}}</div>
              <md-autocomplete id="native-language-id" v-model="selectedNativeLanguage" :md-options="languages"
                               @md-changed="getLanguages" @md-opened="getLanguages"
                               @md-selected="selectNativeLanguage">
                <label>Muttersprache</label>

                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                  <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
                </template>

                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                  "{{ term }}" nicht gefunden
                </template>

              </md-autocomplete>
            </div>
            <div class="cell large-4 medium-4 small-4">
              <div style="display: none">{{selectedFamilyLanguage = labels.language[this.child.familyLanguage]}}</div>
              <md-autocomplete id="family-language-id" v-model="selectedFamilyLanguage" :md-options="languages"
                               @md-changed="getLanguages" @md-opened="getLanguages"
                               @md-selected="selectFamilyLanguage">
                <label>Familiensprache</label>

                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                  <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
                </template>

                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                  "{{ term }}" nicht gefunden
                </template>

              </md-autocomplete>
            </div>
          </div>

          <md-checkbox class="md-primary" v-model="child.nutritionInfo.porkMeatAllowed">Darf Schweinefleisch essen</md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.possiblePreschooler" disabled>Mögliches Vorschulkind
            <md-tooltip>
              Dieses Merkmal wird vom System automatisch ermittelt.
            </md-tooltip>
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.preschooler">Vorschulkind</md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.shareInformationAllowed">Datenweitergabe-Erlaubnis</md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.sibling" disabled>Geschwister <span v-if="child.sibling" style="color: cornflowerblue">{{child.siblingNamesString}}</span></md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.gettingWirtschaftlicheJugendhilfe" disabled>Wirtschaftliche Jugendhilfe</md-checkbox>
          <br>
        </md-tab>
        <md-tab md-label="Gesundheit">
          <md-checkbox class="md-primary" v-model="child.nutritionInfo.allergic">Allergien</md-checkbox>
          <md-field v-if="child.nutritionInfo.allergic" :class="getValidationClass('allergies')" style="margin-bottom: 8px;">
            <label for="allergies">Lebensmittel</label>
            <md-input name="allergies" id="allergies" autocomplete="off" v-model="child.nutritionInfo.allergies"
                      :disabled="sending"/>
            <span class="md-error" v-if="!$v.child.allergies.required">Lebensmittel bitte angeben</span>
          </md-field>
          <br>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-checkbox class="md-primary" v-model="child.exemptionMeaslesVaccination">Freistellung Masernimpfung</md-checkbox>
            </div>
            <div v-if="!child.exemptionMeaslesVaccination" class="md-layout-item" style="padding-left: 20px;">
              <md-checkbox class="md-primary" v-model="child.exemptionSecondMeaslesVaccination">Freistellung 2. Masernimpfung</md-checkbox>
            </div>
          </div>
          <br>
          <div v-if="!child.exemptionMeaslesVaccination" class="md-layout">
            <div class="md-layout-item">
              <md-datepicker id="first-measles-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="firstMeaslesVaccinationDate" md-immediately :disabled="sending">
                <label>Erste Masernimpfung</label>
              </md-datepicker>
            </div>
            <div v-if="!child.exemptionSecondMeaslesVaccination" class="md-layout-item" style="padding-left: 30px;">
              <md-datepicker id="second-measles-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="secondMeaslesVaccinationDate" md-immediately :disabled="sending">
                <label>Zweite Masernimpfung</label>
              </md-datepicker>
            </div>
            <div v-else class="md-layout-item" style="padding-left: 30px;"></div>
          </div>

        </md-tab>
        <md-tab md-label="Zustimmungen"
                :md-template-data="{ badge: !child.masterdata.dsgvoConfirmationObtained, child: child }">
          <md-checkbox class="md-primary" v-model="child.masterdata.dsgvoConfirmationObtained" disabled>
            <div :class="checkBoxWarningClass(!child.masterdata.dsgvoConfirmationObtained)" >
              Einwilligung zur Datenverarbeitung gegeben
            </div>
            <md-tooltip>
              <div v-if="!child.parentalConsents.dsgvoConfirmationObtained">
                Die Einwilligung gemäß Datenschutz-Grundverordnung (Art. 6 DSGVO)<br>
                zur Verarbeitung der personenbezogenen Daten des Kindes muss<br>
                seitens eines Erziehungsberechtigten schriftlich gegeben sein.
              </div>
            </md-tooltip>
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.parentalConsents.applyPlasterSalveAllowed">Pflaster applizieren /
            Salbengabe
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.parentalConsents.beingPhotographedPrivatelyAllowed">Fotonutzung
            für
            interne Zwecke erlaubt
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.parentalConsents.beingPhotographedPubliclyAllowed">Fotonutzung
            für
            öffentliche Zwecke erlaubt
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.parentalConsents.contactToAnimalsAllowed">Kontakt mit Tieren
            erlaubt
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.parentalConsents.goSwimmingAllowed">Schwimmbadbesuch inkl.
            Schwimmen
            erlaubt
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.parentalConsents.removeTickAllowed">Zeckenentfernung erlaubt
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.parentalConsents.ridingCarAllowed">Beförderung in Kraftfahrzeugen
            erlaubt
          </md-checkbox>
          <br>
          <md-checkbox class="md-primary" v-model="child.parentalConsents.applySuncreamAllowed">Sonnencreme anwenden
            erlaubt
          </md-checkbox>
          <br>
        </md-tab>

        <md-tab v-if="child.facilityReference && child.facilityReference !== '*'" md-label="Individuell">
          <FreeAttributesForm :freeAttributes="child.freeAttributes" @saveAndExitTo="(route) => $emit('saveAndExitTo', route)"></FreeAttributesForm>
        </md-tab>
      </md-tabs>
    </div>

  </div>

</template>

<script>
  import jQuery from 'jquery';
  import {validationMixin} from 'vuelidate';
  import Vue from 'vue';
  import Spinner from 'vue-simple-spinner';
  import HttpErrorHandler from '../../services/HttpErrorHandler';
  import ChildService from '../../services/ChildService';
  import {
    required,
  } from 'vuelidate/lib/validators'
  import ContactForm from './ContactForm';
  import FreeAttributesForm from "@/components/forms/FreeAttributesForm";

  export default {
    name: 'ChildForm',
    mixins: [validationMixin],

    components: {
      FreeAttributesForm,
      'vue-simple-spinner': Spinner,
      ContactForm,
    },

    props: {
      child: {
        required: true,
      },
      kibig: {
        required: false
      },
      compensation: {
        required: false
      },
      emergency: {
        required: false
      },
      insurance: {
        required: false
      },
      sending: {
        required: true
      },
    },

    created() {
      this.formatEntryDate();
      this.formatExitDate();
      this.formatVaccinationDates();
      this.resetPromotionCommunities();
      this.initSyncHandler(true);
    },

    beforeDestroy() {
      this.initSyncHandler(false)
    },

    mounted() {
      this.buildCompensationData();
    },

    watch: {
      child() {
        this.buildCompensationData();
      },
    },

    data: () => ({
      compensationYears: [],
      compensationMonthsForYear: {},
      compensationMonthsValuesForYear: {},

      selectedCompensationYears: [],

      invalid: true,

      disabledFirstDay: date => {
        const day = date.getDate();
        return day !== 1;
      },

      disabledLastDay: date => {
        const day = date.getDate();
        const dateString = date.getFullYear() + '-' + Number(date.getMonth() + 1) + '-' +  Number(date.getDay() + 1);
        const lastDay = Number(Vue.moment(dateString).endOf('month').format('DD'));
        return day !== lastDay
      },

      entryDate: '',
      validEntryDate: false,

      exitDate: '',
      validExitDate: false,

      loading: false,
      promotionCommunities: [],
      searchTimer: null,

      countries: [],
      selectedCountryOfOrigin: null,
      languages: [],
      selectedNativeLanguage: null,
      selectedFamilyLanguage: null,

      firstMeaslesVaccinationDate: null,
      secondMeaslesVaccinationDate: null,
    }),

    validations: {
      child: {
        divisiontype: {
          required,
        },
        promotionCommunityName: {
        },
        entryDate: {
          required,
        },
        allergies: {
        },
        insuranceName: {
        },
        insuranceNumber: {
        },
      },
    },

    methods: {
      displayElement(display) {
        return display? '' : 'display: none;'
      },

      onUpdateCompensationSelection() {
        Object.keys(this.child.beitragsersatz).forEach(key => this.child.beitragsersatz[key] = false);
        this.selectedCompensationYears.forEach(year => {
          for (let month of this.compensationMonthsForYear[year]) {
            let mm = Vue.moment(year + '-' + month, 'YYYY-MMMM').format('MM');
            let key = year + mm;
            this.child.beitragsersatz[key] = !!this.compensationMonthsValuesForYear[year].includes(month);
          }
        })
      },

      buildCompensationData() {
        this.compensationYears = [];
        this.selectedCompensationYears = [];
        this.compensationMonthsForYear = {};

        Object.keys(this.child.beitragsersatz).forEach(key => {
          let year = Vue.moment(key, 'YYYYMM').format('YYYY');
          if (!this.compensationYears.includes(year)) {
            this.compensationYears.push(year);
            this.selectedCompensationYears.push(year);

            let compensationMonths = [];
            let compensationMonthsValues = [];
            Object.entries(this.child.beitragsersatz).forEach(entry => {
              let yy = Vue.moment(entry[0], 'YYYYMM').format('YYYY');
              if (yy === year) {
                let mm = Vue.moment(entry[0], 'YYYYMM').format('MMMM');
                compensationMonths.push(mm);
                if (entry[1] === true) {
                  compensationMonthsValues.push(mm);
                }
              }
            });

            this.compensationMonthsForYear[year] = compensationMonths;
            this.compensationMonthsValuesForYear[year] = compensationMonthsValues;
          }
        });
      },

      initSyncHandler(init) {
        if (init) {
          setTimeout(() => {
            jQuery('#entry-date-dp').children('input').bind('blur', () => {
              this.entryDate = jQuery('#entry-date-dp').children('input').val();
              this.writeBackDates();
            });
            jQuery('#exit-date-dp').children('input').bind('blur', () => {
              this.exitDate = jQuery('#exit-date-dp').children('input').val();
              this.writeBackDates();
            });
            jQuery('#first-measles-date-dp').children('input').bind('blur', () => {
              this.firstMeaslesVaccinationDate = jQuery('#first-measles-date-dp').children('input').val();
              this.writeBackDates();
            });
            jQuery('#second-measles-date-dp').children('input').bind('blur', () => {
              this.secondMeaslesVaccinationDate = jQuery('#second-measles-date-dp').children('input').val();
              this.writeBackDates();
            });
            jQuery('#child-promotion-community-name-id').children('.md-menu').children('input').bind('focus', () => {
              setTimeout(() => jQuery('#child-promotion-community-name-id').children('.md-menu').children('input').trigger('click'), 250);
            });
            jQuery('#country-of-origin-id').children('.md-menu').children('input').bind('focus', () => {
              setTimeout(() => jQuery('#country-of-origin-id').children('.md-menu').children('input').trigger('click'), 250);
            });
            jQuery('#native-language-id').children('.md-menu').children('input').bind('focus', () => {
              setTimeout(() => jQuery('#native-language-id').children('.md-menu').children('input').trigger('click'), 250);
            });
          }, 500);
        } else {
          jQuery('#entry-date-dp').children('input').unbind('blur');
          jQuery('#exit-date-dp').children('input').unbind('blur');
          jQuery('#first-measles-date-dp').children('input').unbind('blur');
          jQuery('#second-measles-date-dp').children('input').unbind('blur');
          jQuery('#child-promotion-community-name-id').children('input').unbind('focus');
          jQuery('#country-of-origin-id').children('input').unbind('focus');
          jQuery('#native-language-id').children('input').unbind('focus');
        }
      },

      updateDatesFromDatePickers() {
        this.entryDate = jQuery('#entry-date-dp').children('input').val();
        this.exitDate = jQuery('#exit-date-dp').children('input').val();
        this.firstMeaslesVaccinationDate = jQuery('#first-measles-date-dp').children('input').val();
        this.secondMeaslesVaccinationDate = jQuery('#second-measles-date-dp').children('input').val();
      },

      formatEntryDate() {
        if (this.entryDate == null) {
          this.child.entryDate = null;
        }
        if (this.child.entryDate) {
          this.entryDate = Vue.moment(this.child.entryDate).format('DD.MM.YYYY');
        }
        this.validEntryDate = true;
      },

      formatExitDate() {
        if (this.exitDate == null) {
          this.child.exitDate = null;
        }
        if (this.child.exitDate) {
          this.exitDate = Vue.moment(this.child.exitDate).format('DD.MM.YYYY');
        }
        this.validExitDate = true;
      },

      formatVaccinationDates() {
        if (this.child.firstMeaslesVaccinationDate) {
          this.firstMeaslesVaccinationDate = Vue.moment(this.child.firstMeaslesVaccinationDate).format('DD.MM.YYYY');
        }
        if (this.child.secondMeaslesVaccinationDate) {
          this.secondMeaslesVaccinationDate = Vue.moment(this.child.secondMeaslesVaccinationDate).format('DD.MM.YYYY');
        }
      },

      getValidationClass(fieldName) {
        let field = this.$v.child[fieldName];

        if (field) {
          return {
            'md-invalid': (field.$invalid && field.$dirty) ||
              (field.$model === 'Invalid date' && field.$dirty)
          }
        }
      },

      writeBackDates() {
        if (this.entryDate !== '' && this.entryDate != null) {
          this.child.entryDate = Vue.moment(this.entryDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        } else {
          this.child.entryDate = null;
        }

        if (this.exitDate !== '' && this.exitDate != null) {
          this.child.exitDate = Vue.moment(this.exitDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        } else {
          this.child.exitDate = null;
        }

        if (this.firstMeaslesVaccinationDate !== '' && this.firstMeaslesVaccinationDate != null) {
          this.child.firstMeaslesVaccinationDate = Vue.moment(this.firstMeaslesVaccinationDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        } else {
          this.child.firstMeaslesVaccinationDate = null;
        }

        if (this.secondMeaslesVaccinationDate !== '' && this.secondMeaslesVaccinationDate != null) {
          this.child.secondMeaslesVaccinationDate = Vue.moment(this.secondMeaslesVaccinationDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        } else {
          this.child.secondMeaslesVaccinationDate = null;
        }
      },

      validateChild() {
        this.updateDatesFromDatePickers();
        this.writeBackDates();

        this.$v.$touch();
        this.invalid = this.$v.$invalid;

        if (this.child.entryDate === 'Invalid date') {
          this.invalid = true;
        }
      },

      getPromotionCommunities() {
        if (this.child.promotionCommunityName && this.child.promotionCommunityName.length > 1) {
          clearTimeout(this.searchTimer);
          this.searchTimer = setTimeout(() => {
            if (this.child.promotionCommunityName.length > 1) {
              this.searchCommunitiesByName(this.child.promotionCommunityName);
            }
          }, 250);
        } else {
          this.resetPromotionCommunities();
        }
      },

      searchCommunitiesByName(name) {
        this.loading = true;
        ChildService.getCommunitiesByName(name)
          .then(response => {
            this.loading = false;
            let communities = response.data;
            this.promotionCommunities = [];

            let i = 0;
            communities.forEach(community => {
              if (i < 100) {
                this.promotionCommunities.push({
                  promotionCommunityKey: community.commKey,
                  promotionCommunityName: community.name
                });
                i++;
              }
            })
          })
          .catch(e => {
            this.loading = false;
            this.resetPromotionCommunities();
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Förderkommunen');
          })
      },

      selectPromotionCommunity(community) {
        if (community) {
          this.child.promotionCommunityName = community.promotionCommunityName;
          this.child.promotionCommunityKey = community.promotionCommunityKey;
        }
      },

      resetPromotionCommunities() {
        if (this.child && this.child.promotionCommunityName && this.child.promotionCommunityName.length > 1) {
          this.promotionCommunities = [{
            promotionCommunityKey: this.child.promotionCommunityKey,
            promotionCommunityName: this.child.promotionCommunityName,
          }];
        } else {
          this.promotionCommunities = [{
            promotionCommunityKey: '',
            promotionCommunityName: 'Keine Förderkommune ausgewählt',
          }];
        }
      },

      getCountries(searchTerm) {
        this.countries = new Promise(resolve => {
          window.setTimeout(() => {
            if (!searchTerm) {
              resolve(this.labels.availableCountryEntries)
            } else {
              const term = searchTerm.toLowerCase();
              resolve(this.labels.availableCountryEntries.filter(({name}) => name.toLowerCase().includes(term)))
            }
          }, 5)
        })
      },

      selectCountry(country) {
        this.selectedCountryOfOrigin = country.name;
        this.child.countryOfOrigin = country.id;
      },

      getLanguages(searchTerm) {
        this.languages = new Promise(resolve => {
          window.setTimeout(() => {
            if (!searchTerm) {
              resolve(this.labels.availableLanguageEntries)
            } else {
              const term = searchTerm.toLowerCase();
              resolve(this.labels.availableLanguageEntries.filter(({name}) => name.toLowerCase().includes(term)))
            }
          }, 5)
        })
      },

      selectNativeLanguage(language) {
        this.selectedNativeLanguage = language.name;
        this.child.nativeLanguage = language.id;
      },

      selectFamilyLanguage(language) {
        this.selectedFamilyLanguage = language.name;
        this.child.familyLanguage = language.id;
      },

      checkBoxWarningClass(warn) {
        return warn ? 'cb-warn' : '';
      },
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },
    },
  };
</script>

<style lang="scss" scoped>
  .md-checkbox {
    margin: 5px;
  }

  .badge {
    width: 19px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    right: 2px;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.05em;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }

  .cb-warn {
    color: red;
    font-weight: bold;
  }

  .md-content {
    max-height: 450px;
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>

